/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Tooltip from "@cx/ui/Tooltip";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import has from "lodash/has";
import isNumber from "lodash/isNumber";
import TruncateText from "@cx/ui/TruncateText";
import "./ServiceDetails.scss";
import {
  EDIT_SERVICE_WRAPPER,
  EDIT_MENU_PACKAGE_WRAPPER,
  GlobalOpsServiceType
} from "../../constants/pages.constants";
import {
  DealerPublishedCategory,
  OperationSources,
  QuoteServiceTypes
} from "../page-wrapper/constants/page-wrapper.constants";
import ConfirmPopup from "../ui/modals/ConfirmPopup";
import { defaultToZeroIfNullOrEmpty } from "../../utils/value.util";
import { doesEmpty } from "../../utils/object";
import { AppContext } from "../../state/app-context";
import { UnitOfMeasureMap } from "../../constants/module.constants";
import { convertMinutesToHours, extractNotes } from "../utils/data-transformer";
import csrService from "./services/csr.service";
import { loadQuote } from "../page-wrapper/services/quote-api.service";
import { Actions, useNewQuoteContext } from "../../state/NewQuoteContext";
import selectServiceFormat from "../utils/select-service-format";
import { priceValueFormatter } from "../utils/format";
import MenuService from "./MenuService";
import MenuInspections from "./MenuInspections";
import { getDMSPartsPricingForService } from "./services/service-details.service";
import axiosService from "../../api/xmmAxios";
import modifyPartsService from "./services/modify-parts.service";
import ServiceDetailsSection from "../repair-order/components/service-details-section/service-details-section.component";
import SubletsInformation from "../repair-order/components/adjustment-information/sublets-information.component";
import EditButtonDropdown from "./components/edit-button-dropdown.component";
import * as gtmEvent from "../utils/gtag/gtag-event.util";
import {
  LINE_SUBLET,
  MODIFY_CATALOG_LINE_DISCOUNT,
  MODIFY_CATALOG_LINE_FEE
} from "../repair-order/constants/adjustment.constant";
import { PartsLookupModule } from "../../PartsLookupModule";
import summaryServiceFormat from "../page-wrapper/utils/summary-service-format";
import {
  getPartAvailabilityLabel,
  isPartInstock
} from "../../PartsLookupModule/utils/helper.util";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import { resetDmsPending } from "../../PartsLookupModule/utils/parts.util";
import {
  hasEditQuoteAccessNew,
  shouldHideQuoteForEng,
  showTooltip
} from "../page-wrapper/utils/quote-util";
import quoteService from "./services/quote.service";
import { toast } from "@cx/ui/Toast";
import QuoteStatusConstant, {
  roStatusOptions,
  paymentStatus,
  payTypeCodes,
  warrantySubmissionStates,
  warrantyBadgeLabels,
  invoiceBadgeLabels,
  serviceLineBadgeLabels,
  partStatus,
  badgeColors
} from "../../constants/quote-status.constants";
import IconDrag from "@cx/ui/Icons/IconDrag";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import globalOperationsService from "../page-wrapper/services/global-operations.service";
import { appSources, appTypes } from "../../constants/app.constants";
import ServiceCatalogFeeDetailComponent from "../repair-order/components/catalog-fee/component/service-catalog-fee-detail.component";
import CatalogDiscountInformation from "../repair-order/components/catalog-discount/components/catalog-discount-information.component";
import { isDMSPlus } from "../../api/app.util";
import format from "../repair-order/utils/format";
import ServiceComebackBanner from "../repair-order/components/service-comeback/service-comeback-banner.component";
import { isDMSPInternalAccAvailable } from "../../EditServiceModule/utils/labor-calculation.util";
import { checkPartsForApproval } from "./utils/modify-parts.util";
import RemovePartModalConfirmation from "./components/remove-part-modal-confirmation.component";

const ServiceDetails = props => {
  const appContext = useContext(AppContext);
  const {
    localeStrings,
    isDebug,
    userPermissions,
    appType,
    isPartsView,
    user: { quoteUserId, id: currentUserId },
    debugMode,
    appSource,
    dealerProperties,
    dealer
  } = appContext;
  const { service, removeService, expandDetails, onOpenComebackModal } = props;
  const { blockUntilCompleted, dispatch, state } = useNewQuoteContext();
  const { makeVariantMap, vehicle, customer, quoteSummary } = state;
  const newCostAllocationAccounts =
    appContext?.dealerProperties?.DMSP_INTERNAL_ACCOUNTS;

  const isDMSPInternalAccountsAvailable = isDMSPInternalAccAvailable(
    newCostAllocationAccounts
  );
  const internalAccount = format.sentenceCase(service?.internalAccount);
  const isInternalPaymentType = service?.payTypeCode === "I";

  const departmentName = format.sentenceCase(service?.subType?.subType);
  const costAllocation = format.sentenceCase(
    service?.allocationSubType?.subType
  );

  const isCSR = appType === "CSR";
  const isMenuService = service.quoteServiceType === QuoteServiceTypes.MENU;
  const [removeServiceMsg] = useState(
    isCSR
      ? localeStrings["sq.newquote.summary.remove_service_alert_msg_csr"]
      : localeStrings["sq.newquote.summary.remove_service_alert_msg"]
  );
  const isWithAdvisor = isCSR && quoteSummary.quoteStatus === "WITH_ADVISOR";
  const isFinalized = isCSR && quoteSummary.quoteStatus === "FINALIZED";
  const isSentQuote =
    !isCSR && quoteSummary.quoteStatus === QuoteStatusConstant.SENT;

  const isEngageSource = appSource === appSources.ENG;
  const isCheckedInRO = [
    roStatusOptions.IN_PROCESS,
    roStatusOptions.WORK_FINISHED,
    roStatusOptions.PRE_INVOICE,
    roStatusOptions.FINALIZED
  ].includes(quoteSummary?.quoteStatus);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.service.id, disabled: isFinalized });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupEPParts, setShowPopupEPParts] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentModeAndService, setCurrentModeAndService] = useState({
    mode: null,
    service: null
  });
  const [deleteService, setDeleteService] = useState(null);
  const menuServices = !service?.menuServices ? [] : service.menuServices;
  const inspections = menuServices.filter(menuService => {
    return selectServiceFormat.isIncludedInspection(menuService);
  });
  const rawService = has(service, "quoteRawService")
    ? JSON.parse(
        has(service.quoteRawService, "rawService")
          ? service.quoteRawService.rawService
          : "{}"
      )
    : {};
  const totalPriceOverridden = has(rawService, "totalPriceOverridden")
    ? rawService.totalPriceOverridden
    : false;

  useComponentDidMount(() => {
    axiosService.setupAxios(appContext);
    fetchRealTimeParts();

    // Function to be executed when the component mounts
    const handleGetPartsPringForService = event => {
      console.log("handleGetPartsPringForService", service);
      const { detail } = event;
      fetchRealTimeParts(detail?.quoteServices);
    };

    // Add event listener to window on component mount
    window.addEventListener(
      "getPartsPricingForService",
      handleGetPartsPringForService,
      false
    );

    // Cleanup function to remove the event listener on component unmount
    return () => {
      window.removeEventListener(
        "getPartsPricingForService",
        handleGetPartsPringForService,
        false
      );
    };
  });

  // common edit handler used of service/menu edits
  const handleEditService = service => {
    console.log(
      "SQ Summary/RO Details on edit service click",
      service.quoteServiceType,
      service
    );
    if (
      service.operationSource !== GlobalOpsServiceType.GLOBALCATALOG &&
      !(
        service.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
        (service.serviceKind === DealerPublishedCategory.REPAIR ||
          service.serviceKind === DealerPublishedCategory.MAINTENANCE)
      ) &&
      !(
        service.quoteServiceType === QuoteServiceTypes.RECALL ||
        service.quoteServiceType === QuoteServiceTypes.DECLINED ||
        service.quoteServiceType === QuoteServiceTypes.MENU
      ) &&
      !(isCSR && service.quoteServiceType === QuoteServiceTypes.RECALL)
    ) {
      return;
    }
    const page = isMenuService
      ? EDIT_MENU_PACKAGE_WRAPPER
      : EDIT_SERVICE_WRAPPER;
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: page
    });
    dispatch({
      type: Actions.SET_CURRENT_EDITING_SERVICE,
      payload: service
    });
    dispatch({
      type: Actions.SET_QUOTE_MODIFIED,
      payload: true
    });
  };

  // @csr-logic
  const hasUnapprovedParts = service?.parts?.some(p => !p.approver);
  const hasPartsWithOutUnitCost = service?.parts?.some(
    p => p?.unitCost === null
  );
  const hasPartsWithoutType = service?.parts?.some(part => {
    const { quantity, quantityAvailable, purchaseType, partType } = part;
    const inStock = isPartInstock(quantity, quantityAvailable);
    return !inStock && !purchaseType && partType !== "fluid";
  });
  const approveAllParts = async () => {
    await blockUntilCompleted(async () => {
      const partsToApprove = service.parts.filter(p => !p.approver);
      await Promise.all(
        partsToApprove.map(p => {
          return modifyPartsService.markPartAsApproved(
            appContext,
            quoteSummary,
            p.quoteServicePartId
          );
        })
      );
      const updatedQuote = await loadQuote({
        confirmationId: quoteSummary.confirmationId,
        localeStrings: appContext.localeStrings,
        dealerCode: appContext.dealer.dealerCode,
        appContext
      });
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: updatedQuote
      });
      await csrService.reAssignCounterPartsPerson(
        appContext,
        quoteSummary,
        currentUserId
      );
    });
  };
  // @csr-logic
  const handleCoreReturn = async ({ serviceParts, totalPartsPrice }) => {
    // console.log(serviceParts, totalPartsPrice);
    const { menuPackage } = state;
    const isMenuPackage = service.operationSource === OperationSources.MENU;
    const updatePayload = {
      serviceParts,
      totalPartsPrice,
      quoteSummary,
      selectedService: service,
      quoteUserId,
      ...(isMenuPackage && { menuPackage })
    };
    let response;
    await blockUntilCompleted(async () => {
      if (!isMenuPackage) {
        response = await modifyPartsService.updateQuoteFromModifyParts(
          updatePayload
        );
      } else {
        response = await modifyPartsService.updateQuoteFromModifyPartsWithMenu(
          updatePayload
        );
      }
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: response
      });
    });
  };

  // @csr-logic emergency purchase order
  const getPurchaseOrderDetails = async poNumber => {
    const response = await csrService.getPurchaseOrderDetailsCall(
      poNumber,
      appContext.dealer.dealerCode
    );

    return response;
  };

  // @csr-logic
  const performPartAction = async ({
    actionType,
    // isApprovedChecked,
    isEPChecked,
    isSOChecked,
    typeOfPurchase,
    quoteServicePartId,
    returnToInventory,
    serviceParts,
    totalPartsPrice,
    locationId
  }) => {
    await blockUntilCompleted(async () => {
      switch (actionType) {
        case "Approve":
          await modifyPartsService.markPartAsApproved(
            appContext,
            quoteSummary,
            quoteServicePartId
          );
          break;
        case "EmergencyPurchase":
          await modifyPartsService.updatePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            isEPChecked ? typeOfPurchase : ""
          );
          break;
        case "SpecialOrder":
          await modifyPartsService.updatePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            isSOChecked ? typeOfPurchase : ""
          );
          break;
        case "Remove":
          await modifyPartsService.deletePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            returnToInventory,
            locationId
          );
          break;
        case "CoreReturn":
          handleCoreReturn({ serviceParts, totalPartsPrice });
          break;
        default:
          break;
      }

      const updatedQuote = await loadQuote({
        confirmationId: quoteSummary.confirmationId,
        localeStrings: appContext.localeStrings,
        dealerCode: appContext.dealer.dealerCode,
        appContext
      });
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: updatedQuote
      });
      await csrService.reAssignCounterPartsPerson(
        appContext,
        quoteSummary,
        currentUserId
      );
    });
  };

  const handleModifyParts = (selectedService, isMenu) => {
    if (isMenu) {
      // @note: In the case of menus, the complete menu record exists in the quote as a service.
      // It contains one or more menuServices, which is what we get as selectedService here.
      dispatch({
        type: Actions.SET_CURRENT_EDITING_MENU_SERVICE,
        payload: {
          menuService: selectedService,
          menuRecord: service
        }
      });
    } else {
      dispatch({
        type: Actions.SET_CURRENT_EDITING_SERVICE,
        payload: selectedService
      });
    }
    dispatch({
      type: Actions.SET_IS_MODIFY_PARTS,
      payload: true
    });
    dispatch({
      type: Actions.SET_QUOTE_MODIFIED,
      payload: true
    });
  };

  const showRemovePopup = service => {
    const hasEPPartsRequested = checkPartsForApproval(service?.parts);

    if (hasEPPartsRequested && isCSR) {
      setShowPopupEPParts(true);
    } else {
      setShowPopup(true);
      setDeleteService(service);
    }
  };

  const removeServiceHandler = async () => {
    if (!isEmpty(deleteService)) {
      gtmEvent.trackGAEventWithParam("ga.newquote.remove_service_click", {
        result: `${deleteService?.operationSource} - ${deleteService?.name}`,
        location: "remove-service"
      });
      await removeService(deleteService);
      setDeleteService(null);
      dispatch({
        type: Actions.SET_QUOTE_MODIFIED,
        payload: true
      });
    }
  };

  const closeModal = () => {
    setShowPopup(false);
    setDeleteService(null);
  };

  const showConfirmationModal = (mode, service) => {
    setShowConfirmation(true);
    setCurrentModeAndService({ mode, service });

    if (mode === "remove") {
      setDeleteService(service);
    } else {
      setDeleteService(null);
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  /**
    Handles the update of a confirmation quote's status and performs related actions based on the current mode.
    This function updates the quote status in the database and triggers appropriate dispatch actions.
    It also handles different modes like 'remove', 'edit', and 'modify' to perform corresponding actions.
    @throws {Error} If there's an issue during the process, an error message is displayed.
  */
  const handleConfirmationQuoteUpdate = async () => {
    try {
      // Extract necessary data from the app context and current state
      const { dealer, user } = appContext;
      const { dealerCode } = dealer;
      const { confirmationId } = quoteSummary;
      const { mode } = currentModeAndService;
      const status = QuoteStatusConstant.PROGRESS;

      // Handle different modes: 'remove', 'edit', and 'modify'
      if (mode === "remove") {
        await removeServiceHandler();
      } else if (mode === "edit") {
        handleEditService(currentModeAndService.service);
      } else if (mode === "modify") {
        handleClick(currentModeAndService.service);
      }

      // @note: read response when quote status updated in DB
      const response = await quoteService.updateQuoteStatus({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        quoteStatus: status
      });

      // If response is valid, dispatch appropriate actions for quote update and status change
      if (!isEmpty(response)) {
        const { confirmationId } = response;
        if (!isEmpty(confirmationId)) {
          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: response
          });
        }
      }
    } catch (error) {
      // Handle errors and display appropriate message
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      if (
        error.hasOwnProperty("response") &&
        error["response"].status === 422
      ) {
        toast.error(
          "This quote has already been converted to an appointment, so it cannot be edited.  Please refresh the page to see the current quote status.",
          {
            closeOnClick: true
          }
        );
      }
      console.error(msg);
    }
  };

  // * For checking whether a particular paytype is closed or not for disabling edit, fee, discount,remove, sublet feature
  const checkServiceLineClosed = () => {
    const servicePayStatus = quoteSummary?.payers?.find(
      payer => payer?.payType === service?.payTypeCode
    );
    const serviceLineClosed = servicePayStatus?.closedDate ? true : false;
    return serviceLineClosed;
  };

  const clsTooltip =
    (service.serviceName || "").trim()?.length > 64
      ? "xmm-tooltip-delay"
      : "hide";
  // @note: previously, we checked if service.parts wasn't empty and its length was > 0
  const partsExist = service.hasOwnProperty("parts") ? true : false;
  const laborExist = !isEmpty(
    service?.quoteServiceType === QuoteServiceTypes.MENU
      ? props?.menuOptions?.menuTotalLabors
      : service?.labor
  );

  const commentsExists = !isEmpty(service.complaint);
  const asrNotesExists = !isEmpty(service.asrNotes) ? true : false;
  const dealershipNotes = extractNotes(service?.dealershipNotes || null);

  const subletExists = service.sublets?.length > 0;
  const showPartLabor = partsExist || laborExist ? true : false;
  const clsRow = expandDetails && showPartLabor ? "op-row modified" : "op-row";
  let laborHtml = null;
  let partsHtml = null;
  let commentsHtml = null;
  let asrNotesHtml = null;
  let dealershipNotesHtml = null;

  const clsLabor = laborExist ? "quote-labor no-border" : "hide";
  const clsPartsTable = partsExist ? "parts-box" : "hide";
  const clsPartShowHide = expandDetails ? "parts-container" : "hide";
  const clsComments = expandDetails
    ? "comments-desc margin-left-12"
    : "comments-desc-truncate-text margin-left-12";

  // TODO: convert laborTime, laborPrice for menuServices & other services here
  const laborObj = {};
  let laborServiceId = "";
  // const isLaborExist = service.hasOwnProperty("labor");
  // @case - Menu: Always read finalLaborPrice,finalPartsPrice returned in quoteService{} called as menu level
  if (isMenuService) {
    // @note: Menu case: service.labor is null; Iterate sum all laborTime of each menu service here
    laborServiceId = service.extServiceId;
    let laborTimeValue = 0;
    props.menuOptions?.menuTotalLabors.forEach(labor => {
      laborTimeValue += doesEmpty(labor.laborTime)
        ? 0
        : Number(labor.laborTime);
    });
    laborObj.laborTime = laborTimeValue;
    // @note: Always read price value from service.finalLaborPrice (consider this total labor price at menu level)
    laborObj.laborPrice = doesEmpty(service.finalLaborPrice)
      ? 0
      : Number(service.finalLaborPrice);
  } else {
    // @case: dealer publish/global repair/declined/recall Services only
    laborServiceId = service.extServiceId;

    if (!isNull(service.labor)) {
      laborObj.laborTime = doesEmpty(service?.labor?.laborTime)
        ? 0
        : Number(service?.labor?.laborTime);
      // @note: labor price in summary should always read from service.finalLaborPrice field
      laborObj.laborPrice = service.finalLaborPrice;
    }
  }

  // @note - labor time always returned in minutes by Quote API. UI will convert time to hours for display purpose
  if (laborExist && !isPartsView) {
    let laborTime = laborObj.laborTime;
    const laborPrice = laborObj.laborPrice;
    if (!doesEmpty(laborTime)) {
      laborTime = convertMinutesToHours(laborTime);
    }
    if (!doesEmpty(laborTime) || defaultToZeroIfNullOrEmpty(laborPrice) !== 0) {
      const laborPriceVal = doesEmpty(laborPrice)
        ? ""
        : `${laborPrice.toFixed(2)}`;
      laborHtml = (
        <div
          className={[clsLabor, "margin-left-12"].join(" ")}
          key={"labor-" + laborServiceId}
        >
          <div className="labor-title">
            <span className="bold">
              {localeStrings["sq.newquote.summary.labor_lbl"]}{" "}
            </span>
            <span className="labor-time">- {laborTime}</span>
            <span className="labor-hours">
              {localeStrings["sq.newquote.summary.hours_lbl"]}
            </span>
          </div>
          {userPermissions.canViewPrices ? (
            <div className="labor-price">
              {priceValueFormatter(laborPriceVal)}
            </div>
          ) : null}
        </div>
      );
    }
  }

  const styleLine = {
    textDecoration: "none"
  };

  // Edit Service Permission
  const quoteStatusEditPermission = hasEditQuoteAccessNew(
    quoteSummary,
    userPermissions
  );

  const hideEditButton = shouldHideQuoteForEng(appSource, quoteSummary);

  // Edit Parts Permission
  const userHasPermissionsForModifyParts =
    userPermissions.canUpdateQuoteServicePart;

  // IMPORTANT: Computed css classes for differnt usecases CSR vs Quoting
  let clsModifyPartsLink = "modify-parts-link hand-cursor";

  if (isCSR) {
    // @csr-logic - hide modify links when quote is finalized
    clsModifyPartsLink =
      quoteSummary?.quoteStatus === "FINALIZED"
        ? "hide-ele"
        : clsModifyPartsLink;
  } else {
    // @sq-logic - hide modify links when quote is completed /converted to Appt/ Expired
    clsModifyPartsLink = quoteStatusEditPermission
      ? clsModifyPartsLink
      : "hide-ele";
  }
  // @note: Modify link won't be rendered for menu services until the whole modify parts flow for menus is completed.
  // @todo: Remove conditional rendering logic after whole modify parts flow for menus is completed
  const handleClick = menuService => {
    const isMenu = menuService ? true : false;
    if (menuService) {
      handleModifyParts(menuService, isMenu);
      gtmEvent.trackGAEventWithParam("ga.newquote.modify_parts_click", {
        result: `${menuService?.operationSource} - ${menuService?.name}`,
        location: "summary-page"
      });
    } else {
      handleModifyParts(service, isMenu);
      gtmEvent.trackGAEventWithParam("ga.newquote.modify_parts_click", {
        result: `${service?.operationSource} - ${service?.name}`,
        location: "summary-page"
      });
    }
  };
  // @note: Modify link and Edit button won't be rendered when Service RO is opened from Engage App, except when the status is WITH_ADVISOR.
  const renderModifyLink = menuService => {
    return userHasPermissionsForModifyParts &&
      !(isEngageSource && isCheckedInRO) ? (
      <div
        className={clsModifyPartsLink}
        id="editModifyLink"
        onClick={
          isSentQuote
            ? () => showConfirmationModal("modify", menuService)
            : () => handleClick(menuService)
        }
      >
        Modify
      </div>
    ) : null;
  };

  // @csr-logic: generic link handler {sublet, discount, Fee}
  const handlePriceAdjustment = (actionType, isMenu, item = null) => {
    if (isMenu) {
      // @note: In the case of menus, the complete menu record exists in the quote as a service.
      // It contains one or more menuServices, which is what we get as selectedService here.
      // dispatch menu action later
    } else {
      console.log("Price Adj click", service);
      dispatch({
        type: Actions.SET_CURRENT_EDITING_SERVICE,
        payload: service
      });
    }
    if (actionType) {
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
        payload: item
      });
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
        payload: actionType
      });
    }
  };

  // @note: previous to US952376, below conditional also checked if service.parts > 0
  if (service.hasOwnProperty("parts")) {
    // For MENU, other service types - always read service.finalPartsPrice to show parts price per service
    const finalPartsPriceOfService = service.finalPartsPrice ?? 0;
    const partsTableHtml = service.parts.map((partObj, index) => {
      const unitQty =
        partObj.partType === "fluid" && partObj.adjustedQuantity > 0
          ? UnitOfMeasureMap[partObj.adjustedUom]
          : "";
      const keyId = "part-" + partObj.extPartId + "-" + index;
      const partNumber = partObj.oemPartNumber || "";
      const { description = "", oilType = "" } = partObj || {};
      const inStock = getPartAvailabilityLabel(partObj);

      return (
        <div className="parts-row" key={keyId}>
          <div className="part-desc">
            <TruncateText
              htmlId="TruncateTextTooltip"
              displayToolTip={showTooltip(description)}
            >
              {description}
              <span className="padding-left-4">{oilType}</span>
            </TruncateText>
          </div>
          <TruncateText
            htmlId="partNumberText"
            displayToolTip={showTooltip(partNumber)}
          >
            <div className="part-number">{partNumber} </div>
          </TruncateText>
          {partObj.hasOwnProperty("adjustedQuantity") ? (
            <div className="qty">
              {localeStrings["sq.newquote.summary.qty_lbl"]}:
              <span className="padding-left-4">
                {partObj.adjustedQuantity > 0 ? partObj.adjustedQuantity : ""}
              </span>
              <span className="padding-left-2">{unitQty}</span>
            </div>
          ) : (
            <div className="qty" />
          )}
          {isCSR ? (
            <div className="partStatus">
              {csrService.buildPartStatusBadge(
                partObj.approver ? partStatus.APPROVED : partStatus.REQUESTED
              )}
            </div>
          ) : null}
          {!totalPriceOverridden && userPermissions.canViewPrices ? (
            <div className="unit-price" style={styleLine}>
              {isNumber(partObj.unitPrice) && partObj.unitPrice !== 0
                ? `${priceValueFormatter(partObj.unitPrice)}`
                : ""}
            </div>
          ) : null}
          {!partObj.dmsPending ? (
            <div
              className={
                inStock === "N/A"
                  ? "not-available-part"
                  : partObj.inStock
                  ? "stock"
                  : "no-stock"
              }
            >
              {inStock}
            </div>
          ) : null}
          {partObj.dmsPending ? (
            <div className="loading">
              <LoadingIndicator
                htmlId={`part-${partObj.extPartId}`}
                size="small"
              />
            </div>
          ) : null}
          {!totalPriceOverridden && userPermissions.canViewPrices ? (
            <div className="part-price" style={styleLine}>
              {isNumber(partObj.partPrice) && partObj.unitPrice !== 0
                ? `${priceValueFormatter(partObj.partPrice)}`
                : ""}
            </div>
          ) : null}
        </div>
      );
    });

    // @note: temporary array of services for which modify parts is enabled
    // @todo: delete when modify parts is enabled should be enabled for all services
    const modifyPartsServices = [
      OperationSources.DEALERCATALOG,
      OperationSources.GLOBALCATALOG,
      OperationSources.DECLINED,
      OperationSources.RECALL
    ];

    const isModifyPartsAllowed =
      userHasPermissionsForModifyParts &&
      (modifyPartsServices.includes(service.operationSource) ||
        modifyPartsServices.includes(service.quoteServiceType));

    const rawOpertionDetailFormat = service => {
      let rawDetails = "";
      switch (service.quoteServiceType) {
        case OperationSources.RECALL:
          rawDetails = summaryServiceFormat.extractRawRecallService(service);
          break;
        case OperationSources.DECLINED:
          rawDetails = summaryServiceFormat.extractRawDeclinedService(service);
          break;
        default:
          rawDetails =
            summaryServiceFormat.formatServiceToRawOperationDetails(service);
          break;
      }
      return rawDetails;
    };
    // partsview for menu case: hide total parts row at menu level
    const clsTotalPartsTitle =
      isPartsView && isMenuService
        ? "hide"
        : "quote-labor no-border margin-left-12";
    const partsHeaderHtml = (
      <div className={clsTotalPartsTitle}>
        <div className="parts-modify">
          <div className="labor-title">
            {" "}
            <span>
              <span className="bold">
                {localeStrings["sq.newquote.summary.parts_lbl"]}{" "}
              </span>
              (
              {isMenuService
                ? props?.menuOptions?.menuTotalParts?.length
                : service?.parts?.length}
              )
            </span>
          </div>
          {expandDetails && isModifyPartsAllowed ? renderModifyLink() : null}
        </div>
        <div className="labor-price">
          {userPermissions?.canViewPrices && finalPartsPriceOfService >= 0
            ? priceValueFormatter(finalPartsPriceOfService.toFixed(2))
            : null}
        </div>
      </div>
    );
    // @csr-logic: For Regular service case, display partsView table; For Menu case {menu record treated as service} hide partsView table at menu level here.
    if (isPartsView && service.quoteServiceType !== QuoteServiceTypes.MENU) {
      partsHtml = (
        <PartsLookupModule
          // @required props
          appType={appType}
          userPermissions={userPermissions}
          debugMode={debugMode}
          isPartsView={isPartsView}
          // @required- - expected values "EDIT_SERVICE" or "MODIFY_PARTS"
          actionType="EDIT_SUMMARY"
          // @required- make passed to support Make dropdown filter logic in Individual Part Tab
          make={!vehicle ? "" : vehicle.make}
          dealerProperties={appContext?.dealerProperties}
          dealerMakesProperty={
            appContext?.dealerProperties?.MANUFACTURER_MAKE_CODE_LIST
          }
          emergencyPartProperty={
            appContext?.dealerProperties?.ENABLE_DMSPLUS_EMERGENCY_PARTS
          }
          // @required- - All flows - pass parts of opentrack API callback response, to refresh recommended grid and selected parts grid as well
          partsPricingAndInventory={state?.partsPricingAndInventory}
          inventoryPartsLocation={state?.inventoryPartsLocation}
          quoteSummary={quoteSummary}
          // @required prop - service object used to read service name, operationSource(global, non-global, menu, price override fields) for conditions.
          // Add service case - parts exist in serivce.parts(non-global);filteredParts(global);
          // Edit service summary (global/non-global)- parts are saved in "filteredParts" using this Action SET_CURRENT_EDITING_SERVICE
          service={service}
          // !remove prop - Edge case - Either remove this prop and use service.parts condtions in parts lookup files; This case only used summary edit service case - filteredParts -> parts extracted from quoteService

          savedParts={service.parts}
          // !remove prop - global repair case, these steps used whether service options dropdown selected, to allow used to click on "MODIFY Parts" button in edit service page.(find alternative)
          completedSteps={{ serviceOptions: true }}
          // !remove prop - Edge case to cover if global repair service has undecode vehicl attrs to show extra grid columns
          selectedVehicleAttributes={
            state?.currentEditingService?.vehicleAttributes
          }
          onPartPricingLookup={async part => {
            const { customer, service } = state;
            return await globalOperationsService.getPricingAndInventoryForCustomPart(
              service,
              rawOpertionDetailFormat(service),
              vehicle,
              customer,
              part
            );
          }}
          // @ERP Handler
          // onSaveHandler={handleSave}
          onPerformPartAction={performPartAction}
          getPurchaseOrderDetails={getPurchaseOrderDetails}
          isCsrFinalized={isFinalized}
        />
      );
    } else {
      partsHtml = (
        <>
          {partsHeaderHtml}
          {service.quoteServiceType !== QuoteServiceTypes.MENU ? (
            <div className={[clsPartShowHide, "margin-left-12"].join(" ")}>
              <div className={clsPartsTable}>{partsTableHtml}</div>
            </div>
          ) : null}
        </>
      );
    }
  }

  let laborFeesHtml = "";
  if (
    userPermissions.canViewFeesDiscountsSublets &&
    service?.catalogFees?.length > 0
  ) {
    // ! Fee 2.0 i.e catalog-fees
    laborFeesHtml = (
      <div key={"fee-" + laborServiceId}>
        <ServiceCatalogFeeDetailComponent
          service={service}
          showDetails={expandDetails}
          onModify={item =>
            handlePriceAdjustment(MODIFY_CATALOG_LINE_FEE, false, item)
          }
          containerClassName="margin-left-12"
          modifyDisabled={
            checkServiceLineClosed() ||
            !userPermissions.canUpdateFeesDiscountsSublets
          }
        />
      </div>
    );
  }

  const renderSubletsHtml = () => {
    if (userPermissions.canViewFeesDiscountsSublets && subletExists) {
      if (dealerProperties?.CSR_SUBLET_FORM_ENABLED === "Y") {
        // Sublet 2.0
        return (
          <div key={"sublet-" + laborServiceId}>
            <SubletsInformation
              data={service.sublets}
              showDetails={expandDetails}
              appType={appType}
              canModify={
                (appType === appTypes.CSR &&
                  !checkServiceLineClosed() &&
                  userPermissions.canUpdateFeesDiscountsSublets) ||
                (appType === appTypes.SQ && quoteStatusEditPermission)
              }
              onModify={item => handlePriceAdjustment(LINE_SUBLET, false, item)}
              containerClassName="margin-left-12"
            />
          </div>
        );
      } else {
        // Sublet 1.0
        const { vendor, poNumber, poPricing } = service.sublets[0];
        const subletTitle = `${vendor} - ${poNumber}`;
        return (
          <div
            className={["quote-labor no-border", "margin-left-12"].join(" ")}
            key={"sublet-" + laborServiceId}
          >
            <div className="labor-title" title={subletTitle}>
              <span>Sublet: </span>
              <span className="labor-time">{subletTitle}</span>
            </div>
            <div className="labor-price">
              {priceValueFormatter(poPricing.subletTotalCost)}
            </div>
          </div>
        );
      }
    }

    return null;
  };

  let laborDiscountHtml = "";
  if (
    userPermissions.canViewFeesDiscountsSublets &&
    service?.catalogDiscounts?.length > 0
  ) {
    laborDiscountHtml = (
      <div key={"discount-" + laborServiceId}>
        <CatalogDiscountInformation
          service={service}
          showDetails={expandDetails}
          onModify={item =>
            handlePriceAdjustment(MODIFY_CATALOG_LINE_DISCOUNT, false, item)
          }
          isFinalized={isFinalized}
          containerClassName="margin-left-12"
          modifyDisabled={
            checkServiceLineClosed() ||
            !userPermissions.canUpdateFeesDiscountsSublets
          }
        />
      </div>
    );
  }
  if (!isCSR && commentsExists && !isPartsView) {
    commentsHtml = (
      <div className="quote-comments no-border margin-left-12">
        <div className="comments-title">Concern</div>
        <div className={clsComments}>{service.complaint}</div>
      </div>
    );
  }

  if (asrNotesExists && !isPartsView) {
    asrNotesHtml = (
      <div className="quote-comments no-border margin-left-12">
        <div className="comments-title">Original ASR notes</div>
        <div className={clsComments}>{service.asrNotes}</div>
      </div>
    );
  }

  if (!isEmpty(dealershipNotes) && !isCSR) {
    dealershipNotesHtml = (
      <div className="quote-comments no-border margin-left-12">
        <div className="comments-title">
          {localeStrings["sq.newquote.summary.dealership_notes_lbl"]}
        </div>
        <div className={clsComments}>{dealershipNotes}</div>
      </div>
    );
  }

  // TODO: For dev testing only, show these fields
  // const appEnv = getAppEnvironment();
  // const isProd = ["stg4", "prod"].includes(appEnv);
  const serviceTypeRow = (
    <div className="quote-source-row">
      <span>{service.quoteServiceType || ""}</span>
      <span>{service.serviceKind || ""}</span>
    </div>
  );
  // TODO: condition to display serviceTypeCode in Details page:
  // service.serviceTypeCode ? service.serviceTypeCode : defaultServiceType
  const serviceTypeCode = () => {
    const defaultServiceTypeValue = JSON.parse(
      service.quoteRawService?.rawService
    )?.defaultServiceType;
    return service.serviceTypeCode
      ? service.serviceTypeCode
      : defaultServiceTypeValue;
  };

  const menuWithInspectionsContent = isPartsView ? null : (
    <MenuInspections inspections={inspections} />
  );
  const inspectionsIds = inspections.map(ins => ins.extServiceId);
  const menuServicesWithoutInspections = menuServices.filter(service => {
    return !inspectionsIds.includes(service.extServiceId);
  });

  // @note: render badges for Menu pkg level only
  const isServiceCompleted = !!service?.completedTime;
  let packageLevelBadge = null;
  if (isCSR && isMenuService) {
    packageLevelBadge = (
      <>
        <hr className="menuService__divider" />
        <div className="service-details-section-badge">
          {!service?.completedTime ? null : (
            <Badge
              htmlId="serviceCompletedBadge"
              color={badgeColors.PURPLE}
              className="m-r"
            >
              {serviceLineBadgeLabels.COMPLETED}
            </Badge>
          )}
          {isServiceCompleted &&
          quoteSummary?.quoteStatus === roStatusOptions.PRE_INVOICE ? (
            <Badge
              htmlId="readyForInvoicingBadge"
              color={
                service?.paymentStatus === paymentStatus.READY
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.paymentStatus === paymentStatus.READY
                ? invoiceBadgeLabels.READY
                : invoiceBadgeLabels.PENDING}
            </Badge>
          ) : null}
        </div>
      </>
    );
  }
  const isTechAssignedToService = () => {
    const canEditService = !userPermissions.canUpdateServiceAttributes;
    if (!canEditService) return true;

    let isTechAssigned = false;
    if (service.quoteServiceType === QuoteServiceTypes.MENU) {
      for (const menuService of menuServices) {
        isTechAssigned = menuService?.technicians?.some(
          tech => tech?.techUser?.extUserId === currentUserId
        );
        if (isTechAssigned) break;
      }
    } else {
      isTechAssigned = service?.technicians?.some(
        tech => tech?.techUser?.extUserId === currentUserId
      );
    }
    return isTechAssigned;
  };

  let editButtonHtml = null;
  if (userPermissions.canEditService) {
    editButtonHtml = !hideEditButton ? (
      <EditButtonDropdown
        userPermissions={userPermissions}
        isMenuService={isMenuService}
        // isServiceLineClosed={checkServiceLineClosed()}
        isDisabled={
          (isCSR && checkServiceLineClosed()) ||
          (!isCSR && !quoteStatusEditPermission)
        }
        editAction={
          isSentQuote
            ? () => showConfirmationModal("edit", service)
            : () => handleEditService(service)
        }
        removeAction={
          isSentQuote
            ? () => showConfirmationModal("remove", service)
            : () => showRemovePopup(service)
        }
        priceAdjustmentAction={handlePriceAdjustment}
        isServiceLineCompletedForMenu={
          isMenuService ? !!service?.completedTime : false
        }
        isTechAssignedToService={isTechAssignedToService()}
        isRecallService={service.quoteServiceType === QuoteServiceTypes.RECALL}
      />
    ) : null;
  }

  const serviceDetailFooter = (
    <div className="quote-action-links">
      <div className="quote-source-row">
        <div className="service-pay-type">
          {isPartsView && isMenuService
            ? null
            : `Pay type: ${service?.payTypeCode || ""}`}
          {!isCSR ? ` - ${service?.payTypeDescription || ""}` : null}

          {isDMSPInternalAccountsAvailable &&
          isInternalPaymentType &&
          internalAccount
            ? ` | Cost allocation: ${internalAccount}`
            : null}
          {!isDMSPInternalAccountsAvailable
            ? (departmentName ? ` | Department: ${departmentName}` : "") +
                (costAllocation
                  ? ` | Cost allocation: ${costAllocation}`
                  : "") || null
            : null}

          {isDMSPlus(dealer?.dmsType) && !isMenuService
            ? ` | Service type: ${serviceTypeCode()}`
            : null}
        </div>

        {/** @todo PayType attr should be included on the quoteService response */}
      </div>
      {isDebug ? serviceTypeRow : null}

      {editButtonHtml ?? null}
      {isCSR && !isFinalized && isPartsView && !isMenuService ? (
        <>
          <Button
            htmlId="modifyPartsButton"
            size="sm"
            buttonStyle="secondary"
            onClick={() => handleModifyParts(service)}
          >
            Modify parts
          </Button>
          {userPermissions.canApproveParts ? (
            <Button
              htmlId="approveAllPartsButton"
              size="sm"
              buttonStyle="secondary"
              disabled={
                !hasUnapprovedParts ||
                isWithAdvisor ||
                hasPartsWithOutUnitCost ||
                hasPartsWithoutType
              }
              title={
                isWithAdvisor
                  ? "Cannot approve parts while RO is still with advisor"
                  : hasPartsWithOutUnitCost
                  ? "Cannot approve parts while part has cost missing"
                  : hasPartsWithoutType
                  ? "Cannot approve parts while part has type missing"
                  : hasUnapprovedParts
                  ? ""
                  : "All of this service's parts are already approved."
              }
              onClick={approveAllParts}
            >
              Approve all parts
            </Button>
          ) : null}
        </>
      ) : null}
    </div>
  );
  const clsHand = showPartLabor ? "op-title hand-cursor" : "op-title";

  let servicePriceVal = "";
  if (defaultToZeroIfNullOrEmpty(service.lineServicePrice) !== 0) {
    servicePriceVal =
      service.lineServicePrice > 0
        ? `${service.lineServicePrice.toFixed(2)}`
        : " ";
  } else if (defaultToZeroIfNullOrEmpty(service.servicePrice) !== 0) {
    servicePriceVal =
      service.servicePrice > 0 ? `${service.servicePrice.toFixed(2)}` : " ";
  }

  const renderMenuServices = menuServices => {
    const menuServiceCommonProps = {
      makeVariantMap,
      vehicle,
      localeStrings,
      UnitOfMeasureMap,
      totalPriceOverridden,
      totalPriceOverride: service.totalPriceOverride,
      quoteMenuRecord: service,
      renderModifyLink,
      expandDetails
    };
    const menuServicesList = menuServices.map((menuService, index) => {
      const serviceKey = !isNull(menuService.extServiceId)
        ? menuService.extServiceId.toString()
        : index;
      return (
        <MenuService
          service={menuService}
          key={`menuService-${serviceKey}`}
          keyCompId={`menuService-${serviceKey}`}
          {...menuServiceCommonProps}
        />
      );
    });
    return (
      <>
        <hr className="menuService__divider" />
        {menuServicesList}
      </>
    );
  };

  const displayMenuDetails = isMenuService && expandDetails;

  async function fetchRealTimeParts(quoteServices) {
    const currentService = quoteServices?.find(
      c => c.quoteServiceId === service.quoteServiceId
    );
    // TODO: turn on part progress indicator
    const quoteService = currentService ?? (quoteServices ? null : service);

    if (quoteService && service.quoteServiceType !== QuoteServiceTypes.MENU) {
      if (quoteService.parts?.every(p => p.approver)) {
        return;
      }

      resetDmsPending(quoteService.parts, true);
      const abortController = axiosService.newAbortController();
      const parts = await getDMSPartsPricingForService({
        payType: service.payTypeCode,
        serviceType: service.serviceTypeCode,
        vehicle,
        service: quoteService,
        commonConsumerId: customer?.commonConsumerId || "",
        abortSignal: abortController.signal
      });
      console.log(
        "Service - fetchRealTimeParts",
        state.partsPricingAndInventory
      );
      dispatch({
        type: Actions.SET_IN_STOCK_PARTS_QUOTE_SERVICE,
        payload: {
          extServiceId: service.extServiceId,
          parts
        }
      });
    }
  }

  const clsIconDrag =
    isPartsView || isFinalized || quoteSummary?.quoteServices?.length === 1
      ? "hide"
      : "icon-drag-class";
  let serviceLineContent = null;
  // @note: render ServiceDetailsSection, badges for regular servies only
  if (isCSR && !isPartsView && !isMenuService) {
    serviceLineContent = (
      <>
        {expandDetails ? (
          <ServiceDetailsSection
            userPermissions={userPermissions}
            service={service}
          />
        ) : null}
        <div className="service-details-section-badge">
          {isServiceCompleted ? (
            <Badge
              htmlId="serviceCompletedBadge"
              color={badgeColors.PURPLE}
              className="m-r"
            >
              {serviceLineBadgeLabels.COMPLETED}
            </Badge>
          ) : null}
          {isServiceCompleted &&
          quoteSummary?.quoteStatus === roStatusOptions.PRE_INVOICE ? (
            <Badge
              htmlId="readyForInvoicingBadge"
              color={
                service?.paymentStatus === paymentStatus.READY
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.paymentStatus === paymentStatus.READY
                ? invoiceBadgeLabels.READY
                : invoiceBadgeLabels.PENDING}
            </Badge>
          ) : null}
          {service?.payTypeCode === payTypeCodes.WARRANTY ? (
            <Badge
              htmlId="readyForInvoicingBadge"
              color={
                service?.warranty?.warrantySubmissionState ===
                warrantySubmissionStates.SUBMITTED
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.SUBMITTED
                ? warrantyBadgeLabels.SUBMITTED_TO_OEM
                : warrantyBadgeLabels.PENDING_SUBMISSION}
            </Badge>
          ) : null}
        </div>
      </>
    );
  }
  return (
    <>
      <div
        style={style}
        ref={setNodeRef}
        className={[
          clsRow,
          "margin-left-12 margin-top-17 margin-right-13 bg-w"
        ].join(" ")}
      >
        <div className="op-name">
          <div className="top-level">
            <IconDrag
              htmlId="iconDrag"
              className={clsIconDrag}
              {...attributes}
              {...listeners}
            />
            <div className={clsHand}>
              <Tooltip
                htmlId="service"
                tooltipContent={service.serviceName || ""}
                position="top"
                className={clsTooltip}
              >
                <div className="sq-truncate-text">
                  {isCSR ? `${service?.serviceLineNumber} - ` : ""}
                  {service.serviceName || ""}
                </div>
              </Tooltip>
            </div>
            {userPermissions.canViewPrices ? (
              <div className="op-price">
                {priceValueFormatter(
                  isCSR
                    ? (service?.finalLaborPrice ?? 0) +
                        (service?.finalPartsPrice ?? 0)
                    : servicePriceVal
                )}
              </div>
            ) : null}
          </div>
          {isCSR && !isPartsView && service.prevRONumber ? (
            <ServiceComebackBanner
              canModify={quoteSummary.quoteStatus !== "FINALIZED"}
              comebackRO={service.overridePrevRONumber}
              containerClassName="op-banner"
              onModify={() =>
                onOpenComebackModal({
                  serviceId: service.extServiceId,
                  prevRONumber: service.prevRONumber,
                  overridePrevRONumber: service.overridePrevRONumber
                })
              }
            />
          ) : null}
          {!isEmpty(menuServices) || !isEmpty(inspections) ? (
            <div className="quote-labor no-border margin-left-12">
              <div>
                <span className="service-count">
                  {!isEmpty(menuServices)
                    ? menuServices?.length - inspections?.length
                    : "0"}
                  {"  Services"}
                </span>
                <span className="service-separator"> | </span>
                <span className="service-count">
                  {!isEmpty(inspections) ? inspections?.length : "0"}
                  {"  Inspections"}
                </span>
              </div>
            </div>
          ) : null}
          {laborHtml}
          {partsHtml}
          {laborFeesHtml}
          {renderSubletsHtml()}
          {laborDiscountHtml}
          {isCSR && !isPartsView ? serviceLineContent : null}
          {displayMenuDetails && !isEmpty(menuServicesWithoutInspections)
            ? renderMenuServices(menuServicesWithoutInspections)
            : null}
          {displayMenuDetails && inspections?.length
            ? menuWithInspectionsContent
            : null}
          {asrNotesHtml}
          {commentsHtml}
          {dealershipNotesHtml}
          {isCSR && !isPartsView ? packageLevelBadge : null}
          {serviceDetailFooter}
        </div>
      </div>
      <ConfirmPopup
        title={localeStrings["sq.common.alert_lbl"]}
        message={removeServiceMsg}
        show={showPopup}
        okText={localeStrings["sq.common.proceed_button"]}
        cancelText={localeStrings["sq.common.cancel_button"]}
        okAction={removeServiceHandler}
        cancelAction={closeModal}
        buttonStyle="danger"
      />
      <RemovePartModalConfirmation
        show={showPopupEPParts}
        setShow={setShowPopupEPParts}
        title={
          localeStrings["sq.newquote.parts.unable_to_remove_service_title"]
        }
        message={
          localeStrings["sq.newquote.parts.unable_to_remove_service_message"]
        }
      />
      <ConfirmPopup
        title={localeStrings["sq.common.alert_lbl"]}
        message={
          localeStrings[
            "sq.newquote.location_bar.sent_quote-status-restart_modal_message"
          ]
        }
        show={showConfirmation}
        okText={localeStrings["sq.common.proceed_button"]}
        cancelText={localeStrings["sq.common.cancel_button"]}
        okAction={handleConfirmationQuoteUpdate}
        cancelAction={closeConfirmationModal}
        buttonStyle="danger"
      />
    </>
  );
};

export default ServiceDetails;

ServiceDetails.propTypes = {
  // events
  removeService: PropTypes.func,
  // data
  expandDetails: PropTypes.bool,
  service: PropTypes.object,
  menuOptions: PropTypes.object,
  dragAndDrop: PropTypes.object,
  onOpenComebackModal: PropTypes.func
};

ServiceDetails.defaultProps = {
  expandDetails: true,
  service: null,
  menuOptions: {
    menuTotalLabors: [],
    menuTotalParts: []
  },
  removeService: () => {}
};
