/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";

import {
  useEditServiceContext,
  Actions
} from "../../../EditServiceModule/state/edit-service.context";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";

import Badge from "@cx/ui/Badge";
import "./csr.scss";

import moment from "moment";
import IconAdd from "@cx/ui/Icons/IconAdd";
import Button from "@cx/ui/Button";
import {
  addTechnicianToService,
  overrideTechnicianTime,
  removeTechnicianFromService,
  startTimeForTechnician,
  stopTimeForTechnician
} from "../../service/advisors.service";
import { toast } from "@cx/ui/Toast";
import ConfirmPopup from "../../../features/ui/modals/ConfirmPopup";
import { getOffsetIsoString } from "../../../features/utils/format";
import isNaN from "lodash/isNaN";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import round from "lodash/round";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import has from "lodash/has";
import NumericEditor from "../editors/numeric.editor";
import TechActionsCellRenderer from "./tech-action-cell-renderer.component";
import IconDelete from "@cx/ui/Icons/IconDelete";
import { QuoteServiceTypes } from "../../../features/page-wrapper/constants/page-wrapper.constants";
import ClockTimer from "./clock-timer.component";
import TechSelectDropdown from "./tech-select-dropdown.component";
import WorkTypeSelectDropdown from "./technician-work-type-select.component";
import BodyMask from "../../../features/ui/reusable/common/body-mask.component";
import { convertMinutesToHours } from "../../utils/time";
import cloneDeep from "lodash/cloneDeep";
import ManagerOverridesComponent from "./technician/manager-overrides/manager-override.component";

const CustomDateComponent = forwardRef((_props, ref) => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const inputRef = React.createRef();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return inputRef.current.value;
    }
  }));

  const handleChange = e => {
    setDate(e.target.value);
  };

  return (
    <input
      type="date"
      value={date}
      onChange={handleChange}
      ref={inputRef}
      style={{ width: "100%" }}
    />
  );
});

CustomDateComponent.displayName = "CustomDateComponent";

const AddTechnician = ({ dealerCode, quoteId }) => {
  const { dispatch, state } = useEditServiceContext();
  const { dealerProperties, loggedInUserDetails, userPermissions } = state;
  const techniciansList = state.technicians || [];
  const [showPopup, setShowPopup] = useState(false);
  const [formattedTechList, setFormattedTechList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [techRowToDelete, setTechRowToDelete] = useState(null);
  const [technicianCollectiveData, setTechnicianCollectiveData] = useState({
    totalFlagTime: 0,
    totalActualTime: 0,
    rowLength: 0
  });
  const [techError, setTechError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOverridePopup, setShowOverridePopup] = useState(false);
  const [techRowToOverride, setTechRowToOverride] = useState(null);

  const gridRef = useRef();

  const isTechTimeTrackingEnabled =
    dealerProperties?.ENABLE_DMSPLUS_TECH_TIME_CAPTURE === "Y";
  const isPrimaryTechFeatureEnabled =
    state?.service?.payTypeCode === "W" || isTechTimeTrackingEnabled;

  useEffect(() => {
    if (techniciansList?.length > 0) {
      const list = buildPersonOptions(techniciansList);
      setFormattedTechList(list);
    }
  }, [techniciansList]);

  useEffect(() => {
    // Check if the service and techniciansList are not null or empty.
    if (
      state.service !== null &&
      techniciansList &&
      techniciansList.length > 0
    ) {
      const formattedTechnicianList = intialFormatTechniciansData(
        state?.service
      );
      // Ensure there is a primary tech if the feature is enabled and applicable.
      if (isPrimaryTechFeatureEnabled) {
        const hasPrimaryTech = formattedTechnicianList.some(
          c => c.isPrimary === true
        );
        if (!hasPrimaryTech && formattedTechnicianList.length === 1) {
          formattedTechnicianList[0].isPrimary = true;
        }
      }

      // Update the component's state with the formatted list and execute any additional logic.
      setRowData(formattedTechnicianList);
      getActualAndFlagTime(formattedTechnicianList);
    }
  }, [state.service, techniciansList]);

  const intialFormatTechniciansData = service => {
    const localFormattedTechnicianList = [];
    // Process only if technicians are linked with the service.
    service?.technicians?.forEach(technician => {
      const techId = technician.techUser?.extUserId || technician.techId;
      const tech = technician?.techUser
        ? technician?.techUser
        : state?.technicians.find(
            t => parseInt(t.id, 10) === parseInt(techId, 10)
          );

      // Construct the techObject with all needed properties.
      const techObject = {
        techName: {
          techName: tech?.extUsername || tech?.userName || "---",
          techId: tech
            ? parseInt(tech?.extUserId || tech.id, 10)
            : parseInt(techId, 10),
          firstName: tech?.firstName,
          lastName: tech?.lastName,
          employeeNumber: tech?.employeeNumber
        },
        actualTimeStart: moment(technician.actualTimeStart).format(
          "YYYY-MM-DD"
        ),
        flagTimeEnd: technician.flagTimeEnd || null,
        actualTimeDuration: technician.actualTimeDuration || null,
        techTimeId: technician.techTimeId || null,
        ...(isPrimaryTechFeatureEnabled && {
          isPrimary: !!technician.isPrimary
        }),
        ...(state.service.payTypeCode === "W" && {
          correctionCode: technician.correctionCode || null
        }),
        ...(isTechTimeTrackingEnabled && {
          workStarted: technician.workStarted || null,
          workEnded: technician.workEnded || null,
          workType: technician.workType || "REPAIR",
          techPeriods: technician.techPeriods || [],
          overrideReason: technician.overrideReason || null,
          isWorkingDurationOverridden:
            technician.isWorkingDurationOverridden || false,
          workingDuration: technician.workingDuration || null,
          status: technician.status || "NOT_STARTED"
        })
      };

      localFormattedTechnicianList.push(techObject);
    });
    return localFormattedTechnicianList;
  };

  const buildPersonOptions = technicians => {
    const techniciansList = [];
    technicians?.map(p => {
      techniciansList.push({
        techName: p.firstName + " " + p.lastName,
        techId: parseInt(p.id, 10),
        firstName: p.firstName,
        lastName: p.lastName,
        employeeNumber: p?.employeeNumber
      });
    });

    return techniciansList;
  };

  const updateTechnicianDataForApp = updatedRows => {
    const updatedtechnicianData =
      getUpdatedTechnicianDataForPayload(updatedRows);
    dispatch({
      type: Actions.SET_TECHNICIAN,
      payload: updatedtechnicianData
    });
    const changed = checkTechnicianChange(updatedtechnicianData);
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "technician",
        value: changed
      }
    });
    setRowData(updatedRows);
    getActualAndFlagTime(updatedRows);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomHeader() {
    const formattedDate = moment().format("YYYY-MM-DD");
    let newRowData = {
      techName: {
        techName: "Select technician",
        techId: null,
        lastName: null,
        firstName: null,
        employeeNumber: null
      },
      actualTimeStart: formattedDate,
      flagTimeEnd: null,
      isPrimary: false,
      actualTimeDuration: null,
      techTimeId: null,
      ...(isTechTimeTrackingEnabled && {
        workStarted: null,
        workEnded: null,
        workType: "REPAIR",
        techPeriods: [],
        overrideReason: null,
        isWorkingDurationOverridden: false,
        status: "NOT_STARTED",
        workingDuration: null
      })
    };

    const handleAddButtonClick = async () => {
      if (
        userPermissions.canPerformTechnicianActions &&
        isTechTimeTrackingEnabled
      ) {
        let cloneTechnician = null;
        let clonedTechnicianWorking = null;
        gridRef.current.api.forEachNode(node => {
          if (node.data?.techName?.techId === loggedInUserDetails?.userId) {
            cloneTechnician = node?.data;
          }
          if (
            node.data?.techName?.techId === loggedInUserDetails?.userId &&
            node.data.status === "WORKING"
          ) {
            cloneTechnician = node?.data;
            clonedTechnicianWorking = node?.data;
          }
        });
        if (clonedTechnicianWorking) {
          try {
            setIsLoading(true);

            await onPerformTechTimeActionHandler(
              "STOP",
              clonedTechnicianWorking
            );
            newRowData = cloneDeep(clonedTechnicianWorking);
            newRowData.isPrimary = false;
            newRowData.workStarted = null;
            newRowData.workEnded = null;
            newRowData.isPrimary = false;
            newRowData.actualTimeDuration = null;
            newRowData.techTimeId = null;
            newRowData.techPeriods = [];
            newRowData.overrideReason = null;
            newRowData.isWorkingDurationOverridden = false;
            newRowData.workingDuration = null;
            newRowData.status = "NOT_STARTED";
            setIsLoading(false);
          } catch (e) {
            console.log(e, "not successfull");
            setIsLoading(false);
          }
        } else {
          newRowData = cloneDeep(cloneTechnician) ?? {};
          newRowData.isPrimary = false;
          newRowData.workStarted = null;
          newRowData.workEnded = null;
          newRowData.isPrimary = false;
          newRowData.actualTimeDuration = null;
          newRowData.techTimeId = null;
          newRowData.techPeriods = [];
          newRowData.overrideReason = null;
          newRowData.isWorkingDurationOverridden = false;
          newRowData.workingDuration = null;
          newRowData.status = "NOT_STARTED";
        }
      }
      gridRef.current.api.applyTransaction({ add: [newRowData] });
      const updatedRows = [];
      const lengthOfData = gridRef.current.api.getDisplayedRowCount();
      gridRef.current.api.forEachNode(node => {
        node.data.flagTimeEnd =
          state?.service?.labor?.time === 0
            ? node.data.flagTimeEnd || null
            : lengthOfData === 1
            ? state?.service?.labor?.time
            : round(
                parseFloat(state?.service?.labor?.time) / lengthOfData,
                1
              ) || null;
        updatedRows.push(node.data);
      });
      updateTechnicianDataForApp(updatedRows);
      newRowData = {
        techName: {
          techName: "Select technician",
          techId: null,
          lastName: null,
          firstName: null,
          employeeNumber: null
        },
        actualTimeStart: formattedDate,
        isPrimary: false,
        flagTimeEnd: null,
        actualTimeDuration: null,
        techTimeId: null,
        ...(isTechTimeTrackingEnabled && {
          workStarted: null,
          workEnded: null,
          workType: "REPAIR",
          techPeriods: [],
          status: "NOT_STARTED",
          workingDuration: null,
          overrideReason: null,
          isWorkingDurationOverridden: false
        })
      };
    };
    return (
      <div>
        <Button
          aria-label="Add"
          htmlId="addTechRowButton"
          style={{ padding: "0px" }}
          icon={<IconAdd htmlId="buttonIcon-iconAdd" />}
          onClick={handleAddButtonClick}
          buttonStyle="secondary"
        />
      </div>
    );
  }

  const getUpdatedTechnicianDataForPayload = technicianPayload => {
    if (technicianPayload.length > 0) {
      const techList = [];
      technicianPayload.map(tech => {
        const techObject = {};
        techObject.techId =
          (tech?.techName?.techId && parseInt(tech?.techName?.techId, 10)) ||
          null;
        const dateString = tech?.actualTimeStart;
        const currentDateTime = getOffsetIsoString(new Date());

        techObject.actualTimeStart = getOffsetIsoString(dateString);
        techObject.flagTimeEnd = parseFloat(tech?.flagTimeEnd) || null;

        techObject.actualTimeDuration =
          parseFloat(tech?.actualTimeDuration) || null;
        techObject.lastModTime = currentDateTime;
        techObject.techTimeId = tech?.techTimeId || null;
        if (isPrimaryTechFeatureEnabled) {
          techObject.isPrimary = tech?.isPrimary || false;
        }
        if (state?.service?.payTypeCode === "W") {
          techObject.correctionCode = tech?.correctionCode || null;
        }

        if (isTechTimeTrackingEnabled) {
          techObject.workType = tech?.workType?.value ?? tech?.workType;
          techObject.workStarted = tech.workStarted || null;
          techObject.workEnded = tech.workEnded || null;
          techObject.techPeriods = tech?.techPeriods || [];
          techObject.workingDuration = tech?.workingDuration || null;
          techObject.status = tech?.status || "NOT_STARTED";
          techObject.overrideReason = tech?.overrideReason || null;
          techObject.isWorkingDurationOverridden =
            tech?.isWorkingDurationOverridden || false;
        }
        techList.push(techObject);
      });
      if (isPrimaryTechFeatureEnabled && techList.length === 1) {
        const hasPrimaryTech = techList.some(c => c.isPrimary === true);
        if (!hasPrimaryTech) {
          techList[0].isPrimary = true;
        }
      }
      return techList;
    } else return [];
  };

  const handleDeleteTechnician = async params => {
    const rowToDelete = params?.data;
    if (rowToDelete?.techTimeId !== null) {
      try {
        setIsLoading(true);
        const serviceId =
          state?.service?.operationSource === QuoteServiceTypes.MENU
            ? state?.service?.serviceMenuId
            : state?.service?.quoteServiceId;
        const techTimeId = rowToDelete.techTimeId;
        const confirmationId = quoteId;
        const response = await removeTechnicianFromService({
          dealerCode,
          confirmationId,
          serviceId,
          techTimeId
        });
        console.log("delete technician", response);
        toast.success("Technician successfully deleted");
        gridRef.current.api.applyTransaction({ remove: [rowToDelete] });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setIsLoading(true);
      gridRef.current.api.applyTransaction({ remove: [rowToDelete] });
    }
    const updatedRows = [];
    let technicianNotSelected = false;
    let flagTimeNotAdded = false;
    console.log(technicianNotSelected, flagTimeNotAdded);

    gridRef.current.api.forEachNode(node => {
      if (
        node.data.techName.techId === null ||
        isNaN(node.data.techName.techId) ||
        node.data.techName.techId === undefined
      ) {
        technicianNotSelected = true;
      }
      if (node.data.flagTimeEnd === null) {
        flagTimeNotAdded = true;
      }
      updatedRows.push(node.data);
    });
    updatedRows.map(c => {
      isEmpty(c?.techName?.techName) === true ||
      c?.techName?.techName === "---" ||
      (has(c, "correctionCode") === true && c?.correctionCode !== null) ||
      !(c?.flagTimeEnd > 0)
        ? setTechError(true)
        : setTechError(false);
    });
    const updatedtechnicianData =
      getUpdatedTechnicianDataForPayload(updatedRows);
    dispatch({
      type: Actions.SET_TECHNICIAN,
      payload: updatedtechnicianData
    });
    const changed = checkTechnicianChange(updatedtechnicianData);
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "technician",
        value: changed
      }
    });
    getActualAndFlagTime(updatedtechnicianData);
    setIsLoading(false);
  };

  //* For checking whether technician data changes or not to confirm about alert modal dialog
  const checkTechnicianChange = updatedtechnicianData => {
    const excludingCompareKeysOriginalTech = [
      "techUser",
      "techName",
      "laborRateCode",
      "fixedCost",
      "actualTimeStart",
      "lastModTime"
    ];
    const excludingCompareKeysUpdatedTech = ["actualTimeStart", "lastModTime"];
    if (isPrimaryTechFeatureEnabled) {
      excludingCompareKeysOriginalTech.push("isPrimary");
    }
    if (state?.service?.payTypeCode !== "W") {
      excludingCompareKeysOriginalTech.push("correctionCode");
    }
    const omittedTechnicianListOriginal =
      state?.currentEditingService?.technicians?.map(tech => {
        const updatedTech = omit(tech, excludingCompareKeysOriginalTech);
        updatedTech.flagTimeEnd = parseFloat(tech.flagTimeEnd) || null;
        updatedTech.actualTimeDuration =
          parseFloat(tech.actualTimeDuration) || null;
        if (isTechTimeTrackingEnabled) {
          updatedTech.workType = tech.workType;
          updatedTech.workStarted = tech.workStarted;
          updatedTech.workEnded = tech.workEnded;
          updatedTech.techPeriods = tech.techPeriods;
          updatedTech.overrideReason = tech?.overrideReason;
          updatedTech.isWorkingDurationOverridden =
            tech?.isWorkingDurationOverridden;
          updatedTech.workingDuration = tech.workingDuration;
          updatedTech.status = tech.status;
        }
        return updatedTech;
      });
    const omittedTechnicianListUpdated = updatedtechnicianData?.map(tech => {
      const updatedTech = omit(tech, excludingCompareKeysUpdatedTech);
      return updatedTech;
    });
    return !isEqual(
      omittedTechnicianListUpdated,
      omittedTechnicianListOriginal
    );
  };

  //* for checking whether flagTimeEnd is not greater than catalog flag time
  const checkFlagTimeLessThanCatalog = () => {
    const flagTimeLessThanCatalogTime =
      parseFloat(technicianCollectiveData.totalFlagTime).toFixed(1) <=
      state?.service?.labor?.time
        ? true
        : false;

    return flagTimeLessThanCatalogTime;
  };

  const RemovePartCellRenderer = ({ params }) => {
    if (!params || !params.data) {
      return "";
    }

    return (
      <div
        className="row-remove"
        onClick={() => {
          if (params?.data?.techTimeId !== null) {
            setShowPopup(true);
            setTechRowToDelete(params);
          } else handleDeleteTechnician(params);
        }}
      >
        <IconDelete htmlId="iconDelete" className="remove" />
      </div>
    );
  };

  const getTechnicianUserName = (value, isPrimary) => {
    let techName = "Select technician";
    if (value?.firstName && value?.lastName) {
      techName = value?.firstName + " " + value?.lastName;
    }
    if (value?.firstName && !value?.lastName) {
      techName = value?.firstName;
    }
    if (!value?.firstName && value?.lastName) {
      techName = value?.lastName;
    }
    if (value?.employeeNumber && techName !== "Select technician")
      techName = value?.employeeNumber + " - " + techName;

    return (
      <div>
        <span>{techName}</span>
        {isPrimary ? (
          <Badge htmlId="technicianName" color="gray" className="tech-badge">
            P
          </Badge>
        ) : null}
      </div>
    );
  };

  const getTechnicianUserNameFormatter = value => {
    let techName = "Select technician";
    if (value?.firstName && value?.lastName) {
      techName = value?.firstName + " " + value?.lastName;
    }
    if (value?.firstName && !value?.lastName) {
      techName = value?.firstName;
    }
    if (!value?.firstName && value?.lastName) {
      techName = value?.lastName;
    }
    if (value?.employeeNumber && techName !== "Select technician")
      techName = value?.employeeNumber + " - " + techName;

    return techName;
  };

  const onPerformTechTimeActionHandler = async (action, data) => {
    let payload = null;
    let techTimeId = data?.techTimeId;
    const serviceId =
      state?.service?.operationSource === "MENU"
        ? state?.service?.serviceMenuId
        : state?.service?.quoteServiceId;
    const confirmationId = quoteId;

    //* NOTE  this is used for saving technician if we click on start timer button and technician is not added before
    if (!techTimeId) {
      const currentDateTime = getOffsetIsoString(new Date());
      payload = [
        {
          op: "add",
          path: "/technicians/-",
          value: {
            techTimeId: null,
            actualTimeDuration: data?.actualTimeDuration,
            lastModTime: currentDateTime,
            techId: data?.techName?.techId,
            actualTimeStart: getOffsetIsoString(data?.actualTimeStart),
            flagTimeEnd: data?.flagTimeEnd,
            isPrimary: data?.isPrimary,
            workType: data?.workType,
            correctionCode: data?.correctionCode || null
          }
        }
      ];

      try {
        setIsLoading(true);

        const response = await addTechnicianToService({
          dealerCode,
          confirmationId,
          serviceId,
          techTimeId,
          payload
        });

        const service = response?.quoteServices?.reduce(
          (foundService, service) => {
            if (foundService) return foundService; // If a service is already found, skip the rest

            if (service?.quoteServiceType === "MENU") {
              // If the service type is "MENU", search within menuServices
              return service?.menuServices?.find(
                menuService =>
                  menuService?.serviceMenuId === state?.service?.serviceMenuId
              );
            } else if (
              service?.quoteServiceId === state?.service?.quoteServiceId
            ) {
              // If not "MENU" type, return the whole service
              return service;
            }

            return null;
          },
          null
        );
        const newlyAddedTechnician = service?.technicians?.filter(
          obj1 =>
            !state?.service?.technicians?.some(
              obj2 => obj1?.techTimeId === obj2?.techTimeId
            )
        );
        techTimeId =
          newlyAddedTechnician.length > 0
            ? newlyAddedTechnician[0]?.techTimeId
            : null;
        const formatData = intialFormatTechniciansData(service);
        setRowData(formatData);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
    // * NOTE this block is used for start and stop technician time
    if (techTimeId) {
      try {
        switch (action) {
          case "START": {
            payload = { status: "WORKING", workType: data?.workType };
            const startResponse = await startTimeForTechnician({
              dealerCode,
              confirmationId,
              serviceId,
              techTimeId,
              payload
            });
            updateTechTimeData(startResponse);
            setIsLoading(false);

            break;
          }
          case "STOP": {
            payload = { status: "STOP" };
            const stopResponse = await stopTimeForTechnician({
              dealerCode,
              confirmationId,
              serviceId,
              techTimeId,
              payload
            });
            updateTechTimeData(stopResponse);
            break;
          }
          case "OVERRIDE": {
            setIsLoading(true);
            payload = {
              workStarted: data?.workStarted,
              workEnded: data.workEnded,
              overrideReason: data.overrideReason
            };
            const overrideResponse = await overrideTechnicianTime({
              dealerCode,
              confirmationId,
              serviceId,
              techTimeId,
              payload
            });
            setIsLoading(false);
            setTechRowToOverride(null);
            updateTechTimeData(overrideResponse);
            break;
          }
        }
      } catch (e) {
        console.log("error", e);
        setIsLoading(false);
      }
    }
  };

  const updateTechTimeData = newRowData => {
    if (gridRef.current && newRowData) {
      const updatedRows = [];
      gridRef.current.api.forEachNode(node => {
        if (node.data.techTimeId == newRowData?.techTimeId) {
          node.data = newRowData;
          const techId = newRowData.techUser?.extUserId;
          node.data.techName = {
            techName: newRowData?.techUser?.extUsername || "---",
            techId,
            firstName: newRowData?.techUser?.firstName,
            lastName: newRowData?.techUser?.lastName,
            employeeNumber: newRowData?.techUser?.employeeNumber
          };
          node.data.actualTimeStart = moment(newRowData.actualTimeStart).format(
            "YYYY-MM-DD"
          );
        }
        updatedRows.push(node.data);
      });
      updateTechnicianDataForApp(updatedRows);
    }
  };

  const customFrameworkComponents = {
    numericEditor: NumericEditor
  };

  const performTechAction = params => {
    switch (params.actionType) {
      case "Remove":
        if (params?.data?.techTimeId !== null) {
          setShowPopup(true);
          setTechRowToDelete(params);
        } else handleDeleteTechnician(params);
        break;
      case "Primary":
        {
          const updatedRows = [];
          let technicianNotSelected = false;

          gridRef.current.api.forEachNode(node => {
            if (
              node.data.techName.techId === null ||
              isNaN(node.data.techName.techId) ||
              node.data.techName.techId === undefined
            ) {
              technicianNotSelected = true;
            }

            if (
              !technicianNotSelected &&
              node.data.techName?.techId === params.data.techName?.techId
            ) {
              if (
                !isNull(params.data.correctionCode) &&
                node.data.correctionCode === params.data?.correctionCode
              ) {
                node.data.isPrimary = params.data.isPrimary;
              }
            }
            updatedRows.push(node.data);
          });
          gridRef.current.api.setRowData(updatedRows);
          updateTechnicianDataForApp(updatedRows);
        }
        break;
      case "Override":
        setShowOverridePopup(true);
        setTechRowToOverride(params.data);
        break;
      default:
        break;
    }
  };

  const columnDefs = [
    {
      headerName: "Technician",
      field: "techName",
      flex: 3,
      editable: false,
      cellClass: params => {
        return (
          params?.value?.techName === "Select technician" ||
          (params?.value?.techName === "---" && "cell-error-red")
        );
      },
      cellRendererFramework: params => {
        return (
          <TechSelectDropdown
            params={params}
            formattedTechList={formattedTechList}
            getTechnicianUserNameFormatter={getTechnicianUserNameFormatter}
            getTechnicianUserName={getTechnicianUserName}
            buildPersonOptions={buildPersonOptions}
            onCellValueChanged={onCellValueChanged}
            canUpdateExistingTechRowTechnician={
              userPermissions.canUpdateExistingTechRowTechnician
            }
          />
        );
      }
    },
    {
      headerName: "Correction code",
      field: "correctionCode",
      singleClickEdit: true,
      cellClassRules: {
        "": params => !isEmpty(params.value),
        "cell-error-red": params => isEmpty(params.value)
      },
      flex: 2,
      hide: state?.service?.payTypeCode !== "W",
      editable: userPermissions.canUpdateTechRowCorrectionCode
    },
    {
      headerName: "Date",
      field: "actualTimeStart",
      flex: 2,
      cellEditorFramework: CustomDateComponent,
      cellClass: params =>
        params.value?.includes("Invalid") === true ||
        (params.value === "" && "cell-error-red"),
      singleClickEdit: true,
      valueFormatter: params => {
        const isValidFormat = moment(
          params.value,
          "YYYY-MM-DD",
          true
        ).isValid();
        if (isValidFormat) {
          const formattedDate = moment(params.value, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          );
          return formattedDate;
        }
        return params.value;
      },
      editable: !!(
        (isTechTimeTrackingEnabled && userPermissions.canUpdateTechRowDate) ||
        !isTechTimeTrackingEnabled
      ) //* redo this with below field
      // hide: isTechTimeTrackingEnabled //* redo this with below field
    },
    // TODO: redo this field
    // {
    //   headerName: "Date",
    //   field: "workStarted",
    //   flex: 2,
    //   cellEditorFramework: CustomDateComponent,
    //   cellEditorParams: params => {
    //     return {
    //       isTechTimeTrackingEnabled,
    //       params
    //     };
    //   },
    //   editable: isTechTimeTrackingEnabled && userPermissions.canUpdateTechRowDate ? true : false,
    //   cellClass: params =>
    //     params.value?.includes("Invalid") === true ||
    //     (params.value === "" && "cell-error-red"),
    //   singleClickEdit: true,
    //   valueFormatter: params => {
    //     const isValidFormat = moment(
    //       params.value,
    //       "YYYY-MM-DD",
    //       true
    //     ).isValid();
    //     if (isValidFormat) {
    //       const formattedDate = moment(params.value, "YYYY-MM-DD").format(
    //         "MM/DD/YYYY"
    //       );
    //       return formattedDate;
    //     }
    //     return params.value;
    //   },
    //   hide: !isTechTimeTrackingEnabled
    // },

    {
      headerName: "Flag time",
      field: "flagTimeEnd",
      cellClass: params => (params?.value > 0 ? "" : "cell-error-red"),
      singleClickEdit: true,
      type: "numberColumn",
      cellEditor: "numericEditor",
      cellEditorParams() {
        const allowDecimals = true;
        const decimalPlaces = 2;
        return { allowDecimals, decimalPlaces };
      },
      editable: userPermissions.canUpdateTechRowFlagTime
    },
    {
      headerName: "Work type",
      field: "workType",
      flex: 1,
      editable: false,
      cellRendererFramework: params => {
        return (
          <WorkTypeSelectDropdown
            params={params}
            onCellValueChanged={onCellValueChanged}
          />
        );
      },
      hide: !isTechTimeTrackingEnabled
    },
    {
      headerName: "Actual time",
      field: "actualTimeDuration",
      singleClickEdit: true,
      flex: isTechTimeTrackingEnabled ? 3 : 1,
      type: "numericColumn",
      minWidth: 300,
      editable: !isTechTimeTrackingEnabled,
      cellEditor: "numericEditor",
      cellStyle: { paddingLeft: "3px", paddingRight: "4px" },
      cellEditorParams() {
        const allowDecimals = true;
        const decimalPlaces = 2;
        return { allowDecimals, decimalPlaces };
      },
      cellRendererFramework: params => {
        if (isTechTimeTrackingEnabled) {
          return (
            <ClockTimer
              data={params.data}
              onPerformTechTimeActionHandler={onPerformTechTimeActionHandler}
              loggedInUserDetails={loggedInUserDetails}
              service={state?.service}
            />
          );
        } else {
          return <span style={{ textAlign: "left" }}>{params.value}</span>;
        }
      }
    },
    {
      field: "Action",
      cellRendererFramework: params => {
        if (isTechTimeTrackingEnabled || has(params.data, "isPrimary"))
          return (
            <TechActionsCellRenderer
              onPerformTechAction={performTechAction}
              data={params.data}
            />
          );
        else return <RemovePartCellRenderer params={params} />;
      },

      headerName: "",
      maxWidth: 45,
      editable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      sortable: false,
      lockVisible: true,
      suppressSizeToFit: false,
      suppressColumnsToolPanel: true,
      enableRowGroup: false,
      cellClass: "xmm-wrap-text",

      headerComponentFramework: CustomHeader,
      headerComponentParams: {
        onButtonClick: () => {
          // Handle button click event
          console.log("Button clicked!");
        }
      }
    }
  ];

  const [gridOptions, _setGridOptions] = useState({
    defaultColDef: {
      editable: true,
      resizable: true,
      flex: 1
    },
    columnTypes: {
      numberColumn: {
        width: 160,
        filter: "agNumberColumnFilter"
      }
    },
    domLayout: "autoHeight",
    rowDragManaged: true,
    animateRows: true
  });

  let columnApi;

  const onGridReady = params => {
    columnApi = params.columnApi;
    console.log(columnApi);
  };

  const partsGridCls = clsx(
    "ag-grid-container",
    "ops-auto-height",
    "ag-theme-balham",
    "m-b-md",
    rowData.length > 3 ? "csr-tech-height-extra" : "csr-tech-height"
  );

  const onCellValueChanged = useCallback(params => {
    console.log("onCellValueChanged", params);

    const updatedRows = [];
    let technicianNotSelected = false;
    let flagTimeNotAdded = false;
    console.log(technicianNotSelected, flagTimeNotAdded);

    gridRef.current.api.forEachNode(node => {
      if (
        node.data.techName.techId === null ||
        isNaN(node.data.techName.techId) ||
        node.data.techName.techId === undefined
      ) {
        technicianNotSelected = true;
      }
      if (node.data.flagTimeEnd === null || node.data.flagTimeEnd === "") {
        flagTimeNotAdded = true;
      }

      updatedRows.push(node.data);
    });
    updateTechnicianDataForApp(updatedRows);
  }, []);

  const getActualAndFlagTime = technicianData => {
    const updatedRows = { totalFlagTime: 0, totalActualTime: 0, rowLength: 0 };
    const isTechnicianWorking = technicianData?.some(
      tech => tech.status === "WORKING"
    );
    technicianData?.map(node => {
      updatedRows.rowLength = updatedRows.rowLength + 1;
      updatedRows.totalFlagTime =
        updatedRows.totalFlagTime + (parseFloat(node?.flagTimeEnd) || 0);
      if (isTechTimeTrackingEnabled) {
        if (!isTechnicianWorking)
          updatedRows.totalActualTime =
            updatedRows.totalActualTime + (node?.workingDuration || 0);
      } else {
        updatedRows.totalActualTime =
          updatedRows.totalActualTime +
          (parseFloat(node?.actualTimeDuration) || 0);
      }
    });
    setTechnicianCollectiveData(updatedRows);

    const isErr = technicianData?.some(
      t =>
        t.techName?.techId === null ||
        !(t?.flagTimeEnd > 0) ||
        t.actualTimeStart === "" ||
        t.actualTimeStart?.includes("Invalid") === true ||
        (state?.service?.payTypeCode === "W" &&
          has(t, "correctionCode") &&
          isNull(t.correctionCode)) ||
        (state?.service?.payTypeCode === "W" &&
          has(t, "correctionCode") &&
          t.correctionCode === "")
    );
    setTechError(isErr);
  };

  const removeTechnicianPopup = (
    <ConfirmPopup
      title="Alert!"
      message="Are you sure you want to remove this technician permanently from the service?"
      show={showPopup}
      okText="Remove"
      cancelText="Cancel"
      okAction={() => {
        setShowPopup(false);
        handleDeleteTechnician(techRowToDelete);
      }}
      cancelAction={() => {
        setShowPopup(false);
        setTechRowToDelete(null);
      }}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  const [minWidth, setMinWidth] = useState("");
  const [width, setWidth] = useState("");

  const cellEditingStarted = params => {
    //* fetching the current width of the popup container
    if (["workType"].includes(params.column.getId())) {
      const el = document.querySelector(
        ".ag-theme-balham.ag-popup .ag-virtual-list-container.ag-rich-select-virtual-list-container"
      );

      if (params.column.getId() === "workType") {
        const el2 = document.querySelector(
          ".ag-theme-balham .ag-rich-select-list"
        );
        el2.classList.remove("ag-rich-select-list");
      }

      setMinWidth(
        params.column.context.beanWrappers.popupService.beanInstance
          .popupList[0].element.style.minWidth
      );
      setWidth(
        params.column.context.beanWrappers.popupService.beanInstance
          .popupList[0].element.style.width
      );
      //* setting the width according to cell width
      params.column.context.beanWrappers.popupService.beanInstance.popupList[0].element.style.minWidth =
        params.column.actualWidth + "px";
      params.column.context.beanWrappers.popupService.beanInstance.popupList[0].element.style.width =
        params.column.actualWidth + "px";
      el.style.width = params.column.actualWidth - 2 + "px";
    }
  };
  const cellEditingStopped = params => {
    if (["workType"].includes(params.column.getId())) {
      try {
        params.column.context.beanWrappers.popupService.beanInstance.popupList[0].element.style.minWidth =
          minWidth;
        params.column.context.beanWrappers.popupService.beanInstance.popupList[0].element.style.width =
          width;
      } catch (e) {
        console.log("CellEditingStopped: ", e);
      }
    }
  };

  const pagemask = isLoading ? (
    <div className="sq-flex-grid-container">
      <BodyMask loadingText="" />
    </div>
  ) : null;

  return (
    <>
      {pagemask}
      <div className="technician-header-class">
        <div>
          Technicians{" "}
          <Badge htmlId="badgeCount" color="gray">
            {technicianCollectiveData.rowLength}
          </Badge>
        </div>
        <div className="technician-header-time">
          <p className="m-r-md">
            <span
              className={`technician-time-text-color ${
                techError ? "tech-error" : "hidden"
              }`}
            >
              Technician details missing
            </span>
            {/*
            These read-only display elements are wrapped in an "update" permission check because they're only there to help
            advisors and managers ensure that the flag times in the tech table add up to the total labor hours for the service.
            If we showed this to users who can't update the flag times in the table, it would just be unnecessary clutter.
            */}
            {userPermissions.canUpdateTechRowFlagTime ? (
              <>
                <span
                  className={`technician-time-text-color ${
                    !checkFlagTimeLessThanCatalog() ? "color-red" : ""
                  }`}
                >
                  Total flag time -{" "}
                </span>
                <b
                  className={` ${
                    !checkFlagTimeLessThanCatalog() ? "color-red" : ""
                  }`}
                >
                  {technicianCollectiveData?.totalFlagTime?.toFixed(2)} Hours
                </b>
              </>
            ) : null}
          </p>
          <p className="m-r-0">
            <span className="technician-time-text-color">
              Total actual time -
            </span>
            <b>
              {isTechTimeTrackingEnabled
                ? convertMinutesToHours(
                    technicianCollectiveData?.totalActualTime
                  )
                : technicianCollectiveData?.totalActualTime?.toFixed(2)}{" "}
              {technicianCollectiveData?.totalActualTime >= 60
                ? "Hours"
                : "Hour"}
            </b>
          </p>
        </div>
      </div>
      <div id="partsGrid" className={partsGridCls}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          stopEditingWhenGridLosesFocus={true}
          overlayNoRowsTemplate="No service technicians."
          frameworkComponents={customFrameworkComponents}
          onCellEditingStarted={cellEditingStarted}
          onCellEditingStopped={cellEditingStopped}
        />
      </div>
      {removeTechnicianPopup}
      {showOverridePopup ? (
        <ManagerOverridesComponent
          showOverridePopup={showOverridePopup}
          setShowOverridePopup={setShowOverridePopup}
          data={techRowToOverride}
          updateManagerOverrideTechnicianTime={onPerformTechTimeActionHandler}
        />
      ) : null}
    </>
  );
};

export default AddTechnician;
