// This file is used for summary edit flow
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import operationDetailsSchema from "../../quote/schemas/operation-details.schema";
import {
  GlobalOpsServiceType,
  IncludedInspectionTags
} from "../constants/page-wrapper.constants";
import { convertHoursToMinutes, convertMinutesToHours } from "./quote-util";
import { priceSourceLabels } from "../../../PartsLookupModule/constants/parts.constants";
import {
  paymentStatus,
  warrantySubmissionStates
} from "../../../constants/quote-status.constants";

const updatePartsIfPersistedPartMatches = (globalOpsParts, persistedParts) => {
  const newParts = !isEmpty(globalOpsParts)
    ? JSON.parse(JSON.stringify(globalOpsParts))
    : [];
  const persistedPartsIds = persistedParts.map(part => part.extPartId);
  newParts.forEach(part => {
    if (persistedPartsIds.includes(Number(part.partId))) {
      const matchingPart = persistedParts.find(
        p => p.extPartId === Number(part.partId)
      );
      part.priceSource = matchingPart.priceSource;
      part.unitPrice = matchingPart.unitPrice;
      part.quantity = matchingPart.adjustedQuantity;
      part.partsPrice = matchingPart.partPrice;
      part.dtDmsPartCode = matchingPart?.dtDmsPartCode || null;
      if (matchingPart.priceSource === priceSourceLabels.MANUAL) {
        part.partPriceSource = priceSourceLabels.MANUAL;
        part.dmsPrice = null;
      }
    }
  });
  console.log("update parts for summary edit", newParts);
  return newParts;
};

// persistedService only to be used when formatting service for summary edit
const formatService = (
  service,
  globalOperationDetails,
  persistedService = {}
) => {
  const rawRecord = service.rawRecord;
  let formattedService = null;
  // Some steps will only proceed when editing a service in summary; in that case, we'll have a persistedService
  const persistedServiceExistsAndHasParts =
    !isEmpty(persistedService) &&
    has(persistedService, "parts") &&
    !isEmpty(persistedService.parts);

  if (rawRecord.operationSource) {
    let globalOpsService = globalOperationDetails;
    globalOpsService = operationDetailsSchema.cast(globalOpsService);
    const rawService = JSON.stringify(globalOpsService);

    if (rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG) {
      if (!isEmpty(globalOpsService.laborApps)) {
        let operation = globalOpsService.laborApps[0];
        const laborHours = has(operation, "laborHours")
          ? operation.laborHours || 0
          : 0;
        // @note: convert hours to minutes, send this laborMins in quote payload
        operation.laborMinutes = convertHoursToMinutes(laborHours);
        operation = updateOperationObject(operation, rawRecord);
        globalOpsService.laborApps[0] = operation;
        if (persistedServiceExistsAndHasParts) {
          globalOpsService.laborApps[0].parts =
            updatePartsIfPersistedPartMatches(
              globalOpsService.laborApps[0].parts,
              persistedService.parts
            );
        }
      }
    } else {
      globalOpsService = updateOperationObject(globalOpsService, rawRecord);
      let updatedParts = [];
      let summaryOperation = {};
      // persistedService will only exist in summary edit
      if (
        persistedServiceExistsAndHasParts &&
        !isEmpty(globalOpsService.laborApps)
      ) {
        updatedParts = updatePartsIfPersistedPartMatches(
          globalOpsService.laborApps[0].parts,
          persistedService.parts
        );
        summaryOperation = globalOpsService.laborApps[0];
        summaryOperation.parts = updatePartsIfPersistedPartMatches(
          globalOpsService.laborApps[0].parts,
          persistedService.parts
        );
      }
      // to be updated when user can select parts & labors
      globalOpsService = {
        ...globalOpsService,
        id: rawRecord.operationId,
        name: rawRecord.operationName,
        parts: !isEmpty(updatedParts)
          ? updatedParts
          : has(globalOpsService, "parts")
          ? globalOpsService.parts
          : [],
        ...(!isEmpty(summaryOperation) && {
          laborApps: [{ ...summaryOperation }]
        })
      };
    }
    formattedService = {
      ...globalOpsService,
      id: rawRecord.operationId,
      serviceKind: rawRecord.serviceKind,
      operationSource: rawRecord.operationSource,
      quoteRawService: { rawService }
    };
  } else {
    const rawService = JSON.stringify(rawRecord);
    formattedService = {
      ...rawRecord,
      serviceId: has(rawRecord, "id") ? rawRecord.id.toString() : "",
      quoteRawService: { rawService }
    };
  }

  return formattedService;
};

const updateOperationObject = (operation, rawRecord) => {
  const operationService = {
    ...operation,
    categoryId: rawRecord.categoryId,
    categoryName: rawRecord.categoryName,
    categoryGroupId: rawRecord.categoryGroupId,
    categoryGroupName: rawRecord.categoryGroupName,
    serviceKind: rawRecord.serviceKind,
    operationSource: rawRecord.operationSource
  };
  return operationService;
};

const isIncludedInspection = service => {
  const { price, serviceCategoryName } = service;
  if (price && price !== 0) {
    return false;
  }
  if (!serviceCategoryName) {
    return false;
  }
  const serviceCategoryLowercase = serviceCategoryName.toLowerCase();
  for (let index = 0; index < IncludedInspectionTags.length; index++) {
    if (
      serviceCategoryLowercase.indexOf(IncludedInspectionTags[index]) !== -1
    ) {
      return true;
    }
  }
  return false;
};

// Method to convert quoteService{rawService} into unified response, used as prop {rawOperationDetails} to edit service
const extractUnifiedServiceObject = quoteService => {
  // quoteRawService.rawService is copy of unified response and should not be modified
  let unifiedResponse = null;
  if (!isEmpty(quoteService)) {
    unifiedResponse = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
  }
  return unifiedResponse;
};

// @todo-edit: Method to construct formatted service {rawRecord} using quoteService object used as prop to edit service module
const extractEditService = quoteService => {
  // Note: quoteRawService.rawService is copy of unified API response and will not have calculated, overriden, final fields
  let unifiedResponse = null;
  let editService = null;
  if (!isEmpty(quoteService)) {
    unifiedResponse = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
    editService = {
      recordId: quoteService.extServiceId || "",
      serviceName: quoteService.serviceName,
      price: quoteService.servicePrice,
      categoryId: quoteService.serviceCategoryId,
      categoryGroupName: "",
      opCode: quoteService.dmsOpcode,
      synonyms: [],
      rawRecord: {
        id: unifiedResponse.operationId || "",
        operationId: unifiedResponse.operationId,
        operationName: quoteService.serviceName,
        operationSource: quoteService.operationSource,
        serviceKind: quoteService.serviceKind,
        opCode: quoteService.dmsOpcode,
        quoteServiceType: quoteService.quoteServiceType,
        categoryId: quoteService.serviceCategoryId,
        categoryName: quoteService.serviceCategory,
        categoryGroupId: "",
        categoryGroupName: "",
        price: quoteService.servicePrice
      }
    };
  }
  return editService;
};

// @todo-edit: This formatter should be same like formatService() written in select-service-format.js
const formatServiceToRawOperationDetails = currentEditingService => {
  const globalOperationDetails = extractUnifiedServiceObject(
    currentEditingService
  );
  const service = extractEditService(currentEditingService);
  console.log(
    "summray formatService- service/globalOperationDetails",
    service,
    globalOperationDetails
  );
  const rawRecord = service.rawRecord;
  let formattedService = null;
  if (rawRecord.operationSource) {
    let globalOpsService = globalOperationDetails;
    globalOpsService = operationDetailsSchema.cast(globalOpsService);
    const rawService = JSON.stringify(globalOpsService);

    // IMPORTANT: unified API response must be unchanged for all service types; parts, labor, prices should be unchanged
    if (rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG) {
      if (!isEmpty(globalOpsService.laborApps)) {
        let operation = globalOpsService.laborApps[0];
        const laborHours = has(operation, "laborHours")
          ? operation.laborHours || 0
          : 0;
        // @note: convert hours to minutes, send this laborMins in quote payload
        operation.laborMinutes = convertHoursToMinutes(laborHours);
        operation = updateOperationObject(operation, rawRecord);
        globalOpsService.laborApps[0] = operation;
      }
    } else {
      // For non-dealer publish case: unified API response must be unchanged
      globalOpsService = updateOperationObject(globalOpsService, rawRecord);

      globalOpsService = {
        ...globalOpsService,
        id: rawRecord.operationId,
        name: rawRecord.operationName,
        parts: has(globalOpsService, "parts") ? globalOpsService.parts : []
      };
    }
    formattedService = {
      ...globalOpsService,
      id: rawRecord.operationId,
      serviceKind: rawRecord.serviceKind,
      operationSource: rawRecord.operationSource,
      quoteRawService: { rawService }
    };
  } else {
    const rawService = JSON.stringify(rawRecord);
    formattedService = {
      ...rawRecord,
      serviceId: has(rawRecord, "id") ? rawRecord.id.toString() : "",
      quoteRawService: { rawService }
    };
  }
  return formattedService;
};

// Note: quoteRawService.rawService is copy of declined API response and will not have calculated, overriden, final price fields
const extractRawDeclinedService = quoteService => {
  let rawServiceObj = null;
  let editService = null;
  if (!isEmpty(quoteService)) {
    rawServiceObj = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
    let rawService = null;
    // @workaround - old quote saved with extra fields along with formatted rawRecord in rawService
    const rawRecord = has(rawServiceObj, "rawRecord")
      ? rawServiceObj.rawRecord
      : null;
    if (!isEmpty(rawRecord)) {
      rawService = rawRecord;
    } else {
      // @fix - recent declined rawService fixed, to save only API response
      rawService = rawServiceObj;
    }
    // @regression fix
    const operationSource = !quoteService.quoteServiceType
      ? "DECLINED"
      : quoteService.quoteServiceType;
    editService = {
      recordId: quoteService.extServiceId || "",
      serviceName: quoteService.serviceName,
      categoryId: quoteService.serviceCategoryId,
      categoryGroupName: "",
      opCode: quoteService.dmsOpcode,
      synonyms: [],
      source: operationSource,
      asrNotes: quoteService.asrNotes,
      declinedDate: rawService.declinedDate,
      defaultPayTypeCode: "",
      description: quoteService.serviceDescription,
      duration: !isEmpty(quoteService?.labor)
        ? convertMinutesToHours(quoteService?.labor?.laborTime)
        : 0,
      operationSource,
      payTypeGroup: quoteService.payTypeGroup,
      payTypeCode: quoteService.payTypeCode,
      payTypeDescription: quoteService.payTypeDescription,
      serviceTypeCode: quoteService.serviceTypeCode,
      serviceContract: quoteService.serviceContract,
      price: quoteService.servicePrice,
      rawRecord: {
        declinedDate: rawService.declinedDate,
        declinedServicesEnabled: rawService.declinedServicesEnabled,
        declinedServicesPlaceHolderId: rawService.declinedServicesPlaceHolderId,
        description: quoteService.serviceDescription,
        duration: !isEmpty(quoteService?.labor)
          ? convertMinutesToHours(quoteService?.labor?.laborTime)
          : 0,
        id: rawService.id || "",
        name: quoteService.serviceName,
        nameWithNotes: rawService.nameWithNotes,
        opCode: quoteService.dmsOpcode,
        operationId: rawService.id,
        operationItemId: rawService.operationItemId,
        operationSource,
        price: quoteService.servicePrice
      },
      cause: quoteService?.cause || null,
      complaint: quoteService?.complaint || null,
      correction: quoteService?.correction || null
    };
  }
  return editService;
};
// Note: quoteRawService.rawService is copy of declined API response and will not have calculated, overriden, final price fields
const extractRawRecallService = quoteService => {
  let rawServiceObj = null;
  let editService = null;
  if (!isEmpty(quoteService)) {
    rawServiceObj = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
    let rawService = null;
    // @workaround - old quote saved with extra fields along with formatted rawRecord in rawService
    const rawRecord = has(rawServiceObj, "rawRecord")
      ? rawServiceObj.rawRecord
      : null;
    if (!isEmpty(rawRecord)) {
      rawService = rawRecord;
    } else {
      // @fix - recent declined rawService fixed, to save only API response
      rawService = rawServiceObj;
    }
    // @regression fix
    const operationSource = !quoteService.quoteServiceType
      ? "RECALL"
      : quoteService.quoteServiceType;

    editService = {
      recordId: quoteService.extServiceId || "",
      serviceName: quoteService.serviceName,
      categoryId: quoteService.serviceCategoryId,
      categoryGroupName: "",
      opCode: quoteService.dmsOpcode,
      synonyms: [],
      source: operationSource,
      asrNotes: quoteService.asrNotes,
      defaultPayTypeCode: "",
      description: quoteService.serviceDescription,
      duration: !isEmpty(quoteService?.labor)
        ? convertMinutesToHours(quoteService?.labor?.laborTime)
        : 0,
      operationSource,
      payTypeGroup: quoteService.payTypeGroup,
      payTypeCode: quoteService.payTypeCode,
      payTypeDescription: quoteService.payTypeDescription,
      serviceTypeCode: quoteService.serviceTypeCode,
      serviceContract: quoteService.serviceContract,
      price: quoteService.servicePrice,
      rawRecord: {
        campaignDate: rawService.campaignDate,
        campaignId: rawService.campaignId,
        customerVisible: rawService.customerVisible,
        description: quoteService.serviceDescription,
        duration: !isEmpty(quoteService.labor)
          ? convertMinutesToHours(quoteService?.labor?.laborTime)
          : 0,
        id: rawService.id || "",
        isActive: rawService.isActive,
        leadTimeInDays: rawService.leadTimeInDays,
        manufacturerCampaignId: rawService.manufacturerCampaignId,
        name: quoteService.serviceName,
        opCode: quoteService.dmsOpcode,
        operationId: rawService.id,
        operationSource,
        parentServiceId: rawService.parentServiceId,
        recallControl: rawService.recallControl,
        recallServiceParentDuration: rawService.recallServiceParentDuration,
        recallServiceParentId: rawService.recallServiceParentId,
        status: rawService.status,
        statusDate: rawService.statusDate
      },
      cause: quoteService?.cause || null,
      complaint: quoteService?.complaint || null,
      correction: quoteService?.correction || null
    };
  }
  return editService;
};
// @placeholder: This util doing extra check in UI, to verify if all servcied under menu has completedtime, payment status etc
const updateMenuPackageWithBadgeValues = menuServices => {
  const isMenuServicesCompleted = menuServices.every(service => {
    return service.completedTime && service.completedTime !== null;
  });
  const isMenuInvoiceReady = menuServices.every(service => {
    return (
      service.paymentStatus &&
      service.paymentStatus !== null &&
      service.paymentStatus !== paymentStatus.NOTREADY
    );
  });
  const isMenuSubmittedToOem = menuServices.every(service => {
    return (
      service.warranty?.warrantySubmissionState &&
      service.warranty?.warrantySubmissionState ===
        warrantySubmissionStates.SUBMITTED
    );
  });
  return {
    isMenuServicesCompleted,
    isMenuInvoiceReady,
    isMenuSubmittedToOem
  };
};

export default {
  formatService,
  isIncludedInspection,
  extractUnifiedServiceObject,
  extractRawDeclinedService,
  extractRawRecallService,
  formatServiceToRawOperationDetails,
  updateMenuPackageWithBadgeValues
};
