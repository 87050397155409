import React, { useState } from "react";
import "./override-time-switch-button.scss"; // Import your CSS file for styles
import PropTypes from "prop-types";

const OverrideTimeSwitchButton = ({
  data,
  modifierKey,
  updateTimeModifiers
}) => {
  const [isAM, setIsAM] = useState(data === "am");

  const toggleSwitch = () => {
    setIsAM(!isAM);
    updateTimeModifiers(!isAM ? "PM" : "AM", modifierKey);
  };

  return (
    <div className="switch-container">
      <div
        className={`switch-button ${isAM ? "am-active" : "pm-active"}`}
        onClick={toggleSwitch}
      >
        <span className="am-label">AM</span>
        <span className="pm-label">PM</span>
      </div>
    </div>
  );
};

export default OverrideTimeSwitchButton;
OverrideTimeSwitchButton.propTypes = {
  data: PropTypes.string,
  modifierKey: PropTypes.string,
  updateTimeModifiers: PropTypes.func.isRequired
};
