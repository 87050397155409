import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import ManagerOverridesContentComponent from "./manager-override.content.component";
import "./manager-overrides.scss";
import {
  convertDateTimeToTimeFormat,
  isWorkStartedBeforeWorkEnded,
  timePattern,
  getLocalTimezone
} from "../../../../utils/time";
import isEmpty from "lodash/isEmpty";

const ManagerOverridesComponent = props => {
  const {
    setShowOverridePopup,
    data,
    showOverridePopup,
    updateManagerOverrideTechnicianTime
  } = props;
  const [overrideData, setOverrideData] = useState(null);
  const timeZone = getLocalTimezone();

  const getParsedOverrideObject = useCallback(data => {
    return {
      overrideReason: data?.overrideReason,
      workStarted: data?.workStarted,
      workEnded: data.workEnded,
      startTime: parseTime(
        convertDateTimeToTimeFormat(data?.workStarted, timeZone)
      )?.time,
      startTimeModifier: parseTime(
        convertDateTimeToTimeFormat(data?.workStarted, timeZone)
      )?.modifier,
      endTime: parseTime(convertDateTimeToTimeFormat(data?.workEnded, timeZone))
        ?.time,
      endTimeModifier: parseTime(
        convertDateTimeToTimeFormat(data?.workEnded, timeZone)
      )?.modifier,
      workingDuration: data?.workingDuration,
      techTimeId: data?.techTimeId
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const overrideObject = getParsedOverrideObject(data);
      setOverrideData(overrideObject);
    }
  }, [data, getParsedOverrideObject]);

  function parseTime(input) {
    const [time, modifier] = input.split(" ");
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format based on AM/PM
    if (modifier.toLowerCase() === "pm" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier.toLowerCase() === "am" && hours === "12") {
      hours = 0;
    }

    return {
      time,
      modifier,
      hours: parseInt(hours, 10),
      minutes: parseInt(minutes, 10)
    };
  }

  const hideModalHandler = () => {
    setShowOverridePopup(false);
  };

  const checkOverrideDataChanges = () => {
    const originalData = getParsedOverrideObject(data);
    const changed =
      overrideData?.startTime !== originalData?.startTime ||
      overrideData?.startTimeModifier !== originalData?.startTimeModifier ||
      overrideData?.endTime !== originalData?.endTime ||
      overrideData?.endTimeModifier !== originalData?.endTimeModifier ||
      overrideData?.overrideReason !== originalData?.overrideReason;
    return changed;
  };

  const checkApplyButtonDisabled = () => {
    const isButtonEnabled =
      checkOverrideDataChanges() &&
      timePattern.test(overrideData?.startTime) &&
      timePattern.test(overrideData?.endTime) &&
      isWorkStartedBeforeWorkEnded(
        overrideData?.workStarted,
        overrideData?.workEnded
      ) &&
      !isEmpty(overrideData?.overrideReason);
    return !isButtonEnabled;
  };

  const updateOverridePayload = overrideData => {
    setOverrideData(overrideData);
  };

  const overrideTechTimeClickHandler = async () => {
    try {
      hideModalHandler();
      await updateManagerOverrideTechnicianTime("OVERRIDE", overrideData);
      setOverrideData(null);
    } catch (e) {
      console.log(e);
    }
  };

  const modalFooter = (
    <div>
      <Button
        htmlId="cancelbtnModal"
        buttonStyle="secondary"
        onClick={hideModalHandler}
      >
        Cancel
      </Button>
      <Button
        htmlId="okbtnModal"
        onClick={overrideTechTimeClickHandler}
        disabled={checkApplyButtonDisabled()}
        buttonStyle={props.okBtnStyle}
        tabIndex="0"
      >
        Apply
      </Button>
    </div>
  );

  return (
    <ModalDialog
      htmlId="managerOverrideModalDialog"
      backdrop="static"
      className="sample-modal-dialog quarter-width manager-override-modal"
      show={showOverridePopup}
      header={<h4 className="modal-title">Manager override</h4>}
      footer={modalFooter}
      onHide={hideModalHandler}
    >
      <div className="sq-flex-container">
        <ManagerOverridesContentComponent
          data={overrideData}
          originalData={data}
          updateOverridePayload={updateOverridePayload}
        />
      </div>
    </ModalDialog>
  );
};

export default ManagerOverridesComponent;
ManagerOverridesComponent.defaultProps = {
  setShowOverridePopup: () => {},
  data: null,
  showOverridePopup: false,
  updateManagerOverrideTechnicianTime: () => {}
};

ManagerOverridesComponent.propTypes = {
  setShowOverridePopup: PropTypes.func.isRequired,
  data: PropTypes.object,
  showOverridePopup: PropTypes.bool.isRequired,
  updateManagerOverrideTechnicianTime: PropTypes.func.isRequired
};
