/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "./reusable/loadingmask/loading-overlay.component";
import useComponentDidMount from "../hooks/useComponentDidMount";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import Tooltip from "@cx/ui/Tooltip";
import SubmitButton from "@cx/ui/SubmitButton";
import Badge from "@cx/ui/Badge";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import SearchableSelect from "@cx/ui/SearchableSelect";
import {
  transformValueIfUndefined,
  isItCorePart,
  isPartInstock,
  getPartAvailabilityLabel,
  hasQuantityAvailable,
  isDifferentValue
} from "../utils/helper.util";
import { refinePriceSource, getPartDescription } from "../utils/parts.util";
import { applications, priceSourceLabels } from "../constants/parts.constants";
import defaultTo from "lodash/defaultTo";
import has from "lodash/has";
import { toEmptyStringIfUndefined } from "../utils/string";
const EmergencyPartsGrid = props => {
  const { parts, purchaseOrders, showEmergencyPartError } = props; // parts as props coming from edit container

  const [maxRows] = useState(props.maxRows || 10);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gridOptions, setGridOptions] = useState({});
  const [gridColumnDefs, setGridColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [domLayout, setDomLayout] = useState(null);
  const [selectedParts, setSelectedParts] = useState([]);
  const [showOrdersList, setShowOrdersList] = useState(false);
  const [purchaseOrderSelected, setPurchaseOrderSelected] = useState({});

  const gridRef = useRef();
  useComponentDidMount(() => {
    const gridOptions = gridConfig();
    setGridOptions(gridOptions);
  });

  useEffect(() => {
    setRowData(parts);
    setGridColumnDefs(getColumnList());
  }, [parts]);

  useEffect(() => {
    gridRef.current.api.refreshCells();
  }, [rowData]);

  useEffect(() => {
    if (selectedParts.length < 1) {
      setShowOrdersList(false);
      setPurchaseOrderSelected({});
    }
  }, [selectedParts]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  const gridConfig = () => {
    return {
      // gridId: prevent unnecessary grid rendering when there are multiiple grids in a page
      gridId: "PurchaseList",
      // ag-grid props
      rowSelection: "multiple", // allows single row selection
      multiSortKey: "ctrl",
      defaultColDef: {
        flex: 1,
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        suppressMenu: true,
        sortingOrder: ["asc", "desc", null],
        minWidth: 50,
        maxWidth: 500,
        autoHeight: true,
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        headerClass: "ag-text-header"
      },
      rowGroup: false,
      columnTypes: {
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        nonEditableColumn: {
          editable: false
        }
      },
      isRowSelectable() {
        return true; // to see checkbox
      },
      // Note: In order to prevent racing issues, not setting frameworkComponents here anymore. Passing them as an object contant below.
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      onColumnMoved: refreshGrid,
      onColumnPinned: refreshGrid,
      hiddenByDefault: true,
      sideBar: false,
      domLayout: "autoHeight",
      adjustGridColumns
    };
  };
  const checkValidUnitCost = params => {
    const result = params?.data?.unitCostOverride
      ? params?.value === "" || params?.value === null
      : params?.data?.unitCost === null || params?.data?.unitCost === "";
    return result;
  };
  /* This selection handler returns selected records from grid */
  const handleSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setSelectedParts(selectedRows);
      if (!selectedParts) {
        setShowOrdersList(false);
      }
    }
  };

  const getColumnList = () => {
    return [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 40,
        minWidth: 40,
        width: 40
      },
      {
        field: "approver",
        headerName: "Status",
        editable: false,
        cellRendererFramework: partStatusCellRenderer,
        cellStyle: { padding: "8px" },
        minWidth: 100,
        maxWidth: 150
      },
      {
        field: "quantity",
        headerName: "Qty",
        headerClass: "ag-text-header",
        cellClass: ["d-block-cell", "editable-cell"],
        cellStyle: {
          color: "black",
          "padding-left": "8px"
        },
        editable: false,
        type: "numberColumn",
        cellRendererFramework: quantityCellRenderer,
        suppressSizeToFit: false,
        minWidth: 80,
        maxWidth: 100
      },
      {
        field: "partName",
        tooltipField: "partName",
        headerName: "Description",
        headerClass: "ag-text-header",
        cellRendererFramework: descriptionCellRenderer,
        sortable: true,
        minWidth: 200,
        maxWidth: 680,
        suppressSizeToFit: false,
        editable: false,
        cellClass: ["xmm-wrap-text", "editable-cell"]
      },
      {
        field: "oemPartNumber",
        tooltipField: "oemPartNumber",
        headerName: "Part number",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        cellStyle: { textOverflow: "ellipsis", padding: "8px" },
        minWidth: 100,
        maxWidth: 150,
        suppressSizeToFit: false
      },
      {
        field: "availability",
        headerName: "Availability",
        headerClass: "ag-text-header",
        cellRendererFramework: availabilityCellRenderer,
        minWidth: 100,
        maxWidth: 120,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "purchaseType",
        headerName: "Type",
        headerClass: "ag-text-header",
        cellClass: "",
        editable: false,
        maxWidth: 130,
        minWidth: 130,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true
      },
      {
        field: "location",
        headerName: "Location",
        headerClass: "ag-text-header",
        cellRendererFramework: locationRenderer,
        minWidth: 100,
        maxWidth: 120,
        suppressSizeToFit: false,
        cellClass: "xmm-wrap-text"
      },
      {
        field: "unitCost",
        headerName: "Cost",
        headerClass: "ag-text-header",
        cellClass: "xmm-grid-price",
        cellClassRules: {
          "unit-cost-cell-error-red xmm-grid-price": params =>
            checkValidUnitCost(params),
          "xmm-grid-price": params => !checkValidUnitCost(params)
        },
        cellRendererFramework: costRenderer,
        cellStyle: {
          "text-align": "right",
          "padding-right": "8px"
        },
        minWidth: 70,
        maxWidth: 80,
        suppressSizeToFit: false,
        editable: false
      },
      {
        field: "unitPrice",
        headerName: "Unit",
        headerClass: "ag-numeric-header",
        cellClass: ["d-block-cell", "editable-cell"],
        type: "numberColumn",
        editable: false,
        cellRendererFramework: priceCellRenderer,
        suppressSizeToFit: false,
        minWidth: 130,
        maxWidth: 150
      },
      {
        field: "partsPrice",
        headerName: "Total",
        editable: false,
        type: "numberColumn",
        cellClass: "xmm-grid-price",
        cellStyle: {
          "text-align": "right",
          "padding-right": "8px"
        },
        headerClass: "ag-numeric-header",
        cellRendererFramework: totalPriceCellRenderer,
        minWidth: 60,
        maxWidth: 90
      }
    ];
  };

  const adjustGridColumns = () => {
    sizeToFit();
    setAutoHeight();
  };

  const setAutoHeight = () => {
    if (rowData) {
      const newDomLayout = rowData.length < maxRows ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        gridApi.setDomLayout(newDomLayout);
        setDomLayout(newDomLayout);
      }
    }
  };

  const applySortConfig = () => {
    const defaultSortModel = [
      {
        colId: "partName",
        sortIndex: 0,
        sort: "asc"
      }
    ];
    assignColumnState(defaultSortModel);
  };

  const assignColumnState = defaultSortModel => {
    gridColumnApi &&
      gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };

  const sizeToFit = () => {
    gridApi && gridApi.sizeColumnsToFit();
  };

  const handleColumnResized = () => {
    gridApi.resetRowHeights();
  };
  const handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      sizeToFit();
    }
  };

  const onFirstDataRendered = () => {
    sizeToFit();
  };

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.closeToolPanel();
    sizeToFit();
    applySortConfig();
  };
  // @todo-poc: make sure unique Id given to rowNodeId
  const getRowNodeId = data => {
    return data.quoteServicePartId;
  };

  const refreshGrid = params => {
    if (params) {
      params.api.refreshCells({ force: true });
    } else {
      gridApi && gridApi.refreshCells({ force: true });
    }
  };
  const partsGridCls = clsx(
    "ag-grid-container",
    rowData && rowData.length < maxRows
      ? "ops-auto-height ops-grid-small-selector"
      : "ops-parts-grid ops-grid-small-selector",
    "ag-theme-balham"
  );

  //* for CSR parts status render in grid
  const partStatusCellRenderer = params => {
    const partsApprover = params?.value;
    return (
      <Badge
        color={
          partsApprover === null || partsApprover === undefined
            ? "gray"
            : "purple"
        }
        htmlId="partsStatusBadge"
      >
        {partsApprover === null || partsApprover === undefined
          ? "Requested"
          : "Approved"}
      </Badge>
    );
  };

  const partNumberCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    return !params.data.oemPartNumber ? "" : params.data.oemPartNumber;
  };
  const quantityCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    const label = qtyFormatter(params);
    // keyId used to be formed with partId. Now, it uses unique rowId coming from API.
    const { rowId, quantityAvailable, isCorePart } = params.data;
    const keyId = "qty_" + rowId;
    const tooltipText = "Quantity Available";
    return (
      <div>
        {transformValueIfUndefined(label)}{" "}
        {toEmptyStringIfUndefined(quantityAvailable) !== "" &&
        !isItCorePart(isCorePart) ? (
          <>
            <span className="qty-separator">&nbsp;/&nbsp;</span>
            <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
              <span className="badge-dms">{quantityAvailable}</span>
            </Tooltip>
          </>
        ) : null}
      </div>
    );
  };
  const qtyFormatter = params => {
    if (!params?.data) {
      return "";
    }
    const { quantity, unitOfMeasure, partType } = params.data;
    let label = "";
    if (partType === "part") {
      label += toEmptyStringIfUndefined(quantity);
    } else if (partType === "fluid") {
      if (toEmptyStringIfUndefined(quantity) !== "" && quantity !== "NS") {
        label += quantity + " " + toEmptyStringIfUndefined(unitOfMeasure);
      }
    }
    return label;
  };
  // @todo-poc: remove notes badge in description
  const descriptionCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    const isM3 = false; // TODO: just a place holder till M3
    // @todo: badges no need to show in description cell
    const {
      partName,
      motorPartName,
      partType,
      notes,
      dmsPending,
      rowId,
      typeOfPurchase,
      oilType
    } = params.data;
    if (dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    if (motorPartName || partType === "part") {
      let notesTooltip;
      if (!isEmpty(notes) && Array.isArray(notes)) {
        const tipNotes = notes.join(", ");
        notesTooltip = (
          <span className="ops-notes">
            <Tooltip
              htmlId={`id_${rowId}`}
              tooltipContent={tipNotes}
              className="list-tooltip"
            >
              <IconInfoOutline className="info" />
            </Tooltip>
          </span>
        );
      } else {
        notesTooltip = null;
      }
      return (
        <div>
          <div className="ops-part-description">
            <span className="ops-description">
              {getPartDescription(partName, oilType)}
            </span>
            {notesTooltip}
            {
              // TODO: enable this in M3
              isM3 && typeOfPurchase === "EP" ? (
                <Badge className="desc-badge" htmlId="badgeCount" color="count">
                  EP
                </Badge>
              ) : null
            }
            {
              // TODO: enable this in M3
              isM3 && typeOfPurchase === "SO" ? (
                <Badge className="desc-badge" htmlId="badgeCount" color="count">
                  SO
                </Badge>
              ) : null
            }
          </div>
        </div>
      );
    }
    return !partName ? "" : getPartDescription(partName, oilType);
  };
  const availabilityCellRenderer = params => {
    if (!params?.data || params?.data?.approver) {
      return "";
    }

    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="availabilityIndicator"
          size="small"
          color="gray"
        />
      );
    }

    const { quantity, quantityAvailable, isCorePart } = params.data;
    // const quantityOnHand = !isNaN(quantityAvailable) ? quantityAvailable : 0;
    const inStock = isPartInstock(quantity, quantityAvailable);
    const inStockLabel = getPartAvailabilityLabel({ isCorePart, inStock });

    return hasQuantityAvailable(quantityAvailable) &&
      !isItCorePart(isCorePart) ? (
      <div className={inStock ? "stock grid" : "no-stock grid"}>
        {inStockLabel}
      </div>
    ) : (
      <div className="not-available grid">N/A</div>
    );
  };

  const locationRenderer = params => {
    if (!params?.data || params?.data?.approver) {
      return "";
    }

    if (params.data.dmsPending) {
      return (
        <LoadingIndicator
          htmlId="locationIndicator"
          size="small"
          color="gray"
        />
      );
    }

    const { location } = params.data;
    return !isEmpty(location) ? (
      <div>{location.name}</div>
    ) : (
      <div className="not-available grid">N/A</div>
    );
  };
  const costRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    if (params.data.dmsPending) {
      return (
        <LoadingIndicator htmlId="costIndicator" size="small" color="gray" />
      );
    }
    const { unitCost = null, unitCostOverride = false } = params.data;
    const formattedCost =
      unitCost !== "" && unitCost !== null && priceValueFormatter(unitCost);
    const divClass = unitCostOverride ? "unit-cost-override" : "";
    return formattedCost === 0 || formattedCost ? (
      <div className={divClass}>{formattedCost}</div>
    ) : (
      <div className="grid-unit-cost">- -</div>
    );
  };
  // Advance price formatter - input value can be 654321, null, 0, "", "65.34"
  const priceValueFormatter = amount => {
    const options = { style: "currency", currency: "USD" };
    const priceFormat = new Intl.NumberFormat("en-US", options).format(
      amount || 0
    );
    return priceFormat;
  };
  // @todo-poc: if unit price edited, then change partPriceSource as "Manual"
  const priceCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    const { dmsPending, unitPrice, dmsPrice, priceSource, partPriceSource } =
      params.data;
    if (dmsPending) {
      return (
        <LoadingIndicator
          htmlId="priceLoadingIndicator"
          size="small"
          color="gray"
        />
      );
    }
    // TODO Verify - read priceSource from catalog API; if null, set MSRP
    const refinedPriceSource = refinePriceSource(priceSource);
    const tempPriceSource = !priceSource
      ? priceSourceLabels.MSRP
      : refinedPriceSource;
    // @note: We use partPriceSource to hold DMS api value and when user modifies unitPrice cell
    const partPriceType = !partPriceSource ? tempPriceSource : partPriceSource;
    let pricePerItem;
    if (partPriceType === priceSourceLabels.MANUAL) {
      pricePerItem = Number(params.value);
    } else {
      pricePerItem = !dmsPrice ? unitPrice : dmsPrice;
    }
    const priceTypeSpan =
      partPriceType === priceSourceLabels.DMS ? (
        <span className="badge-dms">{partPriceType}</span>
      ) : (
        <span className="badge-msrp">{partPriceType}</span>
      );
    return (
      <div className="cx-badge-flex">
        {priceValueFormatter(pricePerItem)}
        {priceTypeSpan}
      </div>
    );
  };

  const totalPriceCellRenderer = params => {
    const { quantity = 0, unitPrice = 0 } = params?.data || {};
    const totalPrice = quantity * unitPrice;

    return priceValueFormatter(totalPrice);
  };

  // @todo: placeholder to callback when dms part record is ready when custom part added
  // eslint-disable-next-line unused-imports/no-unused-vars
  const updateGridRow = record => {};

  const onCellEditingStopped = params => {
    refreshGrid(params);
  };

  // Passing frameworkComponents as an object constant to avoid errors
  const customFrameworkComponents = {
    // partsActionMenu: PartsActionMenu,
    customLoadingOverlay: CustomLoadingOverlay,
    customNoRowsOverlay: CustomLoadingOverlay
  };

  const noRowsOverlayTemplate = "<div>No emergency service parts.</div>";

  const handleCellMouseOver = event => {
    const {
      colDef: { field }
    } = event;
  };
  const onPurchaseOrderBlur = cxEvent => {
    onPurchaseOrderChange(cxEvent);
  };
  const onPurchaseOrderChange = cxEvent => {
    const { value } = cxEvent.target;
    if (value) {
      setPurchaseOrderSelected(value[0]);
    }
  };
  const emergencyPartError = (
    <Row>
      <Col xs={5} md={5}>
        <div className="emergency-parts-error-message">
          <span className="emergency-parts-error-icon">!</span>
          <span>
            Currently unable to add part(s) to this PO. Try again later.
          </span>
        </div>
      </Col>
    </Row>
  );
  const selectedPartsLabel = `${selectedParts.length} part(s) selected`;
  const purchaseOrdersOptions = purchaseOrders?.map(m => {
    return { label: m.purchaseOrderNumber + " - " + m.supplier, value: m };
  });
  return (
    <>
      <div style={containerStyle}>
        <div id="partsGrid" className={partsGridCls}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={gridColumnDefs}
            defaultColDef={gridOptions.defaultColDef}
            frameworkComponents={customFrameworkComponents}
            loadingOverlayComponent={gridOptions.loadingOverlayComponent}
            loadingOverlayComponentParams={
              gridOptions.loadingOverlayComponentParams
            }
            overlayNoRowsTemplate={noRowsOverlayTemplate}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onCellMouseOver={handleCellMouseOver}
            onColumnResized={handleColumnResized}
            onGridSizeChanged={handleGridSizeChanged}
            getRowNodeId={getRowNodeId}
            sideBar={gridOptions.sideBar}
            enterMovesDownAfterEdit={true}
            enterMovesDown={true}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            // @note: true - use browser default tooltip instead of ag-grid tooltip
            enableBrowserTooltips={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            rowSelection={gridOptions.rowSelection}
            rowDeselection={true}
            columnTypes={gridOptions.columnTypes}
            multiSortKey={gridOptions.multiSortKey}
            domLayout={domLayout}
            onCellEditingStopped={onCellEditingStopped}
            onSelectionChanged={handleSelectionChanged}
          />
        </div>
      </div>
      <div className="action-section">
        <SubmitButton
          htmlId="addToPurchaseOrder"
          buttonStyle="secondary"
          disabled={selectedParts.length < 1}
          onClick={() => {
            setShowOrdersList(true);
          }}
        >
          Add to Existing PO
        </SubmitButton>
      </div>
      <div className={showOrdersList ? "" : "hide"}>
        <h5>{selectedPartsLabel}</h5>
        <Row>
          <Col xs={4} md={4}>
            <SearchableSelect
              displayPlaceholder={true}
              placeholder="Select"
              className="xmm-scrollable-select"
              htmlId="purchaseListId"
              label="Purchase order"
              name="purchaseListId"
              enableMultiSelect={false}
              maxHeight={150}
              onBlur={onPurchaseOrderBlur}
              onChange={onPurchaseOrderChange}
              options={purchaseOrdersOptions}
              value={purchaseOrderSelected?.label || ""}
              required
              disabled={!purchaseOrders || purchaseOrders.length === 0}
            />
          </Col>
        </Row>
        {showEmergencyPartError ? emergencyPartError : null}
        <Button
          htmlId="saveOrderAction"
          className="float-right"
          buttonStyle="primary"
          disabled={!selectedParts || !purchaseOrderSelected?.label}
          onClick={() => {
            props.handleSaveToPurchaseOrder(
              selectedParts,
              purchaseOrderSelected
            );
          }}
        >
          Save to this PO
        </Button>
      </div>
    </>
  );
};

export default EmergencyPartsGrid;

EmergencyPartsGrid.defaultProps = {
  parts: [],
  purchaseOrders: [],
  showEmergencyPartError: false
};

EmergencyPartsGrid.propTypes = {
  parts: PropTypes.array,
  purchaseOrders: PropTypes.array,
  handleSaveToPurchaseOrder: PropTypes.func,
  showEmergencyPartError: PropTypes.bool
};
